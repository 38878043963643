import { Component, OnInit, Input } from '@angular/core';
import { ApplicationsService } from '../../api/applications.service';
import { Application } from '../../models/application';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { ApplicationVersionDto } from '../../model/models';
import { ApplicationVersion } from '../../models/applicationVersion';
import { AlertService, MessageType } from '../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-application-version-manager',
  templateUrl: './application-version-manager.component.html',
  styleUrls: ['./application-version-manager.component.scss']
})
export class ApplicationVersionManagerComponent implements OnInit {

  public static Instance: ApplicationVersionManagerComponent;

  constructor(public applicationsService: ApplicationsService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer) { }

  @Input() application: Application;

  displayedColumns = ['number', 'name', 'size', 'default', 'upload', 'operations'];
  dataSource = null;

  files: File[] = [];

  ngOnInit() {
    ApplicationVersionManagerComponent.Instance = this;
    // tslint:disable-next-line: triple-equals
    if (this.application != undefined) {
      this.applicationsService
        .getVersions(this.application.id)
        .subscribe(next => {
          this.dataSource = next;
        });
    }
  }

  versionChange(version) {
    this.application.version = version;
  }

  uploadedFilesChange(files: File[]) {
    this.files = files;
    this.applicationsService
      .getVersions(this.application.id)
      .subscribe(next => {
        this.dataSource = next;
      });
  }

  sendFiles(file: File): Observable<HttpEvent<ApplicationVersionDto[]>> {
    return this.applicationsService
      .uploadVersion(ApplicationVersionManagerComponent.Instance.application.id, file, 'events', true);
  }

  deleteVersion(version: ApplicationVersion) {
    this.applicationsService.removeVersion(version.id).subscribe(next => {
      this.dataSource = this.applicationsService.getVersions(this.application.id);
    }, error => {
      this.alertService.showMessage('Nie można usunąć tej wersji aplikacji', MessageType.Error);
    });
  }

  parseFileSize(size) {
    const kb = size / 1000;
    const mb = kb / 1000;
    const gb = mb / 1000;
    return gb >= 1 ? gb.toFixed(3) + ' GB' : (mb >= 1 ? mb.toFixed(3) + ' MB' : kb.toFixed(3) + ' KB');
  }

  getVersion(version: ApplicationVersion) {
    
      this.applicationsService.getVersionFile(version.id).subscribe(result => {
      const blob: Blob = new Blob([result], {type: 'application/octet-stream'});
      const fileName = version.name+'.zip';
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
  
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
  
      document.body.removeChild(a);
      
    });
  }

}

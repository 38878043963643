import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-search-select',
  templateUrl: './multi-search-select.component.html',
  styleUrls: ['./multi-search-select.component.scss']
})
export class MultiSearchSelectComponent implements OnInit {

  // @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  // @Input() set selection(value) {
  //   this.msisdn_confirm = this.msisdn = value;
  // }

  constructor() { }


  ngOnInit() {
  }

}

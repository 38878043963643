import { Component, OnInit, Inject } from '@angular/core';
import { ExhibitsService, ExhibitDto } from '../../../..';
import { Exhibit } from '../../../../models/exhibit';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-create-exhibit-dialog',
  templateUrl: './create-exhibit-dialog.component.html',
  styleUrls: ['./create-exhibit-dialog.component.scss']
})
export class CreateExhibitDialogComponent implements OnInit {
  exhibit: Exhibit = new Exhibit();

  constructor(
    private exhibitsService: ExhibitsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CreateExhibitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  createExhibit() {
    this.exhibitsService
    .add(this.exhibit)
    .pipe(map((exhibitDto: ExhibitDto) => new Exhibit(exhibitDto)))
    .subscribe((next) => {
      this.alertService.showMessage('Pomyślnie dodano stanowisko', MessageType.Info);
      this.exhibit = next;
      this.dialog.close(true);
    },
    (error) => {
      this.alertService.showMessage('Błąd podczas dodawania stanowiska:  ' + error.error, MessageType.Info);
    });
  }

}

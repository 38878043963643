import { ApplicationDto } from '..';
import { PlayerDto } from '../model/playerDto';
import { Exhibit } from './exhibit';
import { ProjectorDto } from '../model/projectorDto';

export class Projector implements ProjectorDto {   
    macAddress: string;   
    ipAddress?: string;  
    type?: string;
    id?: number;
    name: string;
    exhibitId?: number;
    exhibit: Exhibit;
    constructor(projectorDto?: ProjectorDto) {
        if (projectorDto == null) {
            return;
        }
        Object.assign(this, projectorDto);
    }

}


import { RepositoryObjectDto } from '../model/repositoryObjectDto';
import { RepositoryFileDto } from '../model/repositoryFileDto';
import { RepositoryObject } from './repositoryObject';
import { PermissionDto } from '..';

export class Permission implements PermissionDto {

    public constructor(permission?: PermissionDto) {
        if (permission == null) {
            return;
        }
      this.controllerName = permission.controllerName;
      this.methodName = permission.methodName;
      this.granted = permission.granted;
      this.friendlyControllerName = permission.friendlyControllerName;
      this.friendlyMethodName = permission.friendlyMethodName;
    }
    controllerName?: string;
    methodName?: string;
    friendlyMethodName?: string;
    friendlyControllerName?: string;
    granted?: boolean;
  }

import { Component, OnInit, Inject } from '@angular/core';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MultilanguageVarible } from '../../../../models/multilanguageVarible';
import { ApplicationsService } from '../../../../api/api';
import { Application } from '../../../../models/application';
import { TouchSequence } from 'selenium-webdriver';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicFileReaderComponent } from '../../../dynamic-file-reader/dynamic-file-reader.component';

@Component({
  selector: 'app-edit-multilanguage-varibles-dialog',
  templateUrl: './edit-multilanguage-varibles-dialog.component.html',
  styleUrls: ['./edit-multilanguage-varibles-dialog.component.scss']
})
export class EditMultilanguageVariblesDialogComponent implements OnInit {
  multilanguageVaribles: MultilanguageVarible[];
  application: Application;
  constructor(private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private applicationService: ApplicationsService,
    public dialog: MatDialogRef<EditMultilanguageVariblesDialogComponent>, 
    private fileUploadDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.application = this.data.model;
    
    this.applicationService.get(this.application.id).subscribe(result =>{
      this.multilanguageVaribles = result.multilanguageVaribles;
    });
  }

  save() {
    this.application.multilanguageVaribles = this.multilanguageVaribles;
    this.applicationService.update(this.application.id, this.application).subscribe(result => {
      this.alertService.showMessage('Pomyślnie zapisano teksty językowe', MessageType.Info);
      this.dialog.close(true);
    }, error => {
      this.alertService.showMessage('Błąd podczas aktualizacji aplikacji', MessageType.Error);
    });
  }

  export(): void {
    const data = JSON.stringify(this.multilanguageVaribles);
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'LanguageContent.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

import(){
  const dialogRef = this.fileUploadDialog.open(DynamicFileReaderComponent,
    {
      width: '700px',
    });

  dialogRef.afterClosed().subscribe(result => {
    this.multilanguageVaribles = JSON.parse(result);
  });
}



}

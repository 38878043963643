import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Application } from '../../../../models/application';
import { EventEmitter } from 'events';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
  @Input() application: any;

  @Input() readonly: boolean;

  @ViewChild('form', {static: true}) public form: NgForm;
  public test = true;

  constructor() { }

  ngOnInit() {
  }

}


import { ApplicationDto } from '../model/applicationDto';
import { RepositoryObject } from './repositoryObject';
import { Application } from './application';

export class RepositoryDirectory implements RepositoryObject {
  id?: number;
  path?: string;
  directory?: string;
  creationDate?: Date;
  application?: ApplicationDto;
  applicationId?: number;
  discriminator?: string;
  readonly type?: string;
    public constructor(name: string, children: RepositoryObject[], parent: RepositoryObject, application: Application) {
      this.name = name;
      this.children = children;
      this.children.forEach(element => {
        element.parent = this;
      });
      this.type = 'RepositoryDirectory';
      this.parent = parent;
      this.application = application;
    }
    name: string;
    children?: RepositoryObject[];
    parent: RepositoryObject;
  }

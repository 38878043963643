import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/pages/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { UsersComponent } from './components/pages/users/users.component';
import { GroupsComponent } from './components/pages/groups/groups.component';
import { ScheduleComponent } from './components/pages/schedule/schedule.component';
import { Route } from '@angular/router';
import { ApplicationsComponent } from './components/pages/applciations/applications.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { RepositoryComponent } from './components/pages/repository/repository.component';
import { BackupComponent } from './components/pages/backup/backup.component';

export const routing: Route[] = [
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard] },
    {
        path: 'user-management',
        data:
        {
            title: 'Zarządzanie użytkownikami'
        },
        children:
            [
                {
                    path: 'users',
                    component: UsersComponent,
                    data: {
                        title: 'Użytkownicy',
                        icon: 'group'
                    },
                    canActivate: [AuthGuard]
                },
                {
                    path: 'groups',
                    component: GroupsComponent,
                    data: {
                        title: 'Grupy i Uprawnienia',
                        icon: 'group_work'
                    },
                    canActivate: [AuthGuard]
                },
            ]
    },
    {
        path: 'exposition-management',
        data:
        {
            title: 'Zarządzanie ekspozycją'
        },
        children:
            [
                {
                    path: 'devices',
                    component: DevicesComponent,
                    data: {
                        title: 'Urządzenia',
                        icon: 'devices_other'
                    },
                    canActivate: [AuthGuard]
                },
                {
                    path: 'schedule',
                    component: ScheduleComponent,
                    data:
                    {
                        title: 'Harmonogram',
                        icon: 'schedule'
                    },
                    canActivate: [AuthGuard]
                },
            ]
    },
    {
        path: 'content-management',
        data: {
            title: 'Zarządzanie plikami'
        },
        children:
            [
                {
                    path: 'application-repository',
                    component: ApplicationsComponent,
                    data:
                    {
                        title: 'Repozytorium Aplikacji',
                        icon: 'settings_applications'
                    },
                    canActivate: [AuthGuard]
                },
                {
                    path: 'content-repository',
                    component: RepositoryComponent,
                    data:
                    {
                        title: 'Pliki Multimedialne',
                        icon: 'folder'
                    },
                    canActivate: [AuthGuard]
                },
            ]
    }, 
    {
        path: 'system',
        data: {
            title: 'System'
        },
        children:
            [
                {
                    path: 'backups',
                    component: BackupComponent,
                    data:
                    {
                        title: 'Kopie Zapasowe',
                        icon: 'settings_applications'
                    },
                    canActivate: [AuthGuard]
                },
            ]
    },
];

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { map } from 'rxjs/operators';
import { DevicesService } from '../../../../api/devices.service';
import { Device } from '../../../../models/device';
import { DeviceDto } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { DeviceFormComponent } from '../device-form/device-form.component';
import { ExhibitsService, UnassignedDevicesService, ApplicationsService } from '../../../../api/api';
import { NoopAnimationPlayer } from '@angular/animations';
import { Player } from '../../../../models/player';
import { MatSelectChange } from '@angular/material/select';
import { PlayerDto, ExhibitDto, ProjectorDto } from '../../../../model/models';
import { Application } from '../../../../models/application';
import { Projector } from '../../../../models/projector';
import { Exhibit } from '../../../../models/exhibit';

@Component({
  selector: 'app-create-device-dialog',
  templateUrl: './create-device-dialog.component.html',
  styleUrls: ['./create-device-dialog.component.scss']
})
export class CreateDeviceDialogComponent implements OnInit {
  projector = new Projector();
  unassignedPlayers: Player[] = [];
  device: Device = new Device();
  application: Application;
  applications: Application[];
  deviceCreated = false;
  selectedUnassignedPlayer = new Player();
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('formComponent', {static: false}) formComponent: DeviceFormComponent;

  constructor(
    private devicesService: DevicesService,
    private alertService: AlertService,
    private applicationsService: ApplicationsService,
    private unassignedDevicesService: UnassignedDevicesService,
    public dialog: MatDialogRef<CreateDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
    this.applicationsService.getAll()
      .pipe(map(dtos => dtos.map(dto => new Application(dto))))
      .subscribe(result => {
        this.applications = result;
      });
    this.unassignedDevicesService.getAll()
      .pipe(map(dtos => dtos.map(dto => new Player(dto))))
      .subscribe(result => {
        this.unassignedPlayers = result;
      });
  }

  createApp() {
    if (!this.formComponent.form.valid) {
      return;
    }
    if (this.device.type === 'Projektor') {
      this.projector.type = "Projector";
      this.projector.name = this.device.name;
      this.projector.exhibit = this.device.exhibit as Exhibit;
      this.projector.exhibitId = this.device.exhibit.id;
      this.devicesService.add(this.projector as ProjectorDto).subscribe((result) => {
        this.alertService.showMessage('Pomyślnie dodano urządzenie', MessageType.Info);
        this.dialog.close(true);
      });
    }
    else if (this.device.type === 'Komputer') {
    const player: Player = this.device as Player;
    player.computerName = this.selectedUnassignedPlayer.computerName;
    player.ipAddress = this.selectedUnassignedPlayer.ipAddress;
    player.macAddress = this.selectedUnassignedPlayer.macAddress;
    player.systemInfo = this.selectedUnassignedPlayer.systemInfo;
    player.key = this.selectedUnassignedPlayer.key;
    player.application = this.application;

    this.devicesService
      .add(this.device)
      .pipe(map((playerDto: PlayerDto) => new Player(playerDto)))
      .subscribe((next) => {
        this.alertService.showMessage('Pomyślnie dodano urządzenie', MessageType.Info);
        this.device = next;
        this.deviceCreated = true;
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.dialog.close(true);
      },
      (error) => {
        this.alertService.showMessage('Błąd podczas dodawania urządzenia: ' + error.error, MessageType.Info);
      });
    }
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { ChambersService } from '../../../..';
import { CreateChamberDialogComponent } from '../../../dialogs/chamber/create-chamber-dialog/create-chamber-dialog.component';
import { EditChamberDialogComponent } from '../../../dialogs/chamber/edit-chamber-dialog/edit-chamber-dialog.component';
import { ViewChamberDialogComponent } from '../../../dialogs/chamber/view-chamber-dialog/view-chamber-dialog.component';
import { Chamber } from '../../../../models/chamber';
import { ModeratorsComponent } from '../../../dialogs/exhibit/moderators/moderators.component';
import { DevicesService, ExhibitsService } from '../../../../api/api';
import { MapViewDialogComponent } from '../../../dialogs/chamber/map-view-dialog/map-view-dialog.component';


@Component({
  selector: 'app-chambers-view',
  templateUrl: './chambers-view.component.html',
  styleUrls: ['./chambers-view.component.scss']
})
export class ChambersViewComponent extends ExtendedTableComponent implements OnInit {

  @Input() readonly = false;

  displayedColumns = ['select', 'name', 'description', 'details'];

  public selectedChambers: Chamber[] = [];

  @Output() chamberSelection = new EventEmitter();
  @Output() selectionChange = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private chambersService: ChambersService,
    private exhibitsService: ExhibitsService,
    private devicesService: DevicesService,
    private dialog: MatDialog
  ) {
    super();
  }

  createDialog() {
    const dialogRef = this.dialog.open(CreateChamberDialogComponent,
      {
        width: '600px'
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  editDialog(model) {
    const dialogRef = this.dialog.open(EditChamberDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } },
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  moderatorsDialog(model) {
    const dialogRef = this.dialog.open(ModeratorsComponent,
      {
        width: '600px',
        data: { model: { ...model } },
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  viewDialog(model) {
    const dialogRef = this.dialog.open(ViewChamberDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } }
      });
  }  
  
  mapDialog(model) {
    const dialogRef = this.dialog.open(MapViewDialogComponent,
      {
        width: '1200px',
        data: { model: { ...model } }
      });
  }

  deleteSelected() {
    this.selection.selected.forEach(element => {
      this.chambersService.remove(element.id).subscribe(next => {
      }, error => {
        this.alertService.showMessage('Wystąpił błąd podczas usuwania aplikacji: ' + error.error, MessageType.Error);
      }, () => {
        this.alertService.showMessage('Pomyślnie usunięto pomieszczenie', MessageType.Info);
        this.selection.clear();
        this.reloadData();
      });
    });
  }

  action(model, action) {
    this.exhibitsService.getAll(model.id).subscribe(exhibits => exhibits.forEach(exhibit => {
      this.devicesService.getAll(null, exhibit.id).subscribe(devices => devices.forEach(device => {
        this.devicesService.callAction(device.id, action).subscribe(result => {
          this.alertService.showMessage('Akcja wywołana poprawnie', MessageType.Info);
        }, error => {
          this.alertService.showMessage('Błąd podczas wywoływania akcji', MessageType.Error);
          console.error(error);
        });
      }));
    }));
  }


  reloadData() {
    this.loading = true;
    this.chambersService
      .getAll(null)
      .pipe(map(((dtos) => dtos.map(((dto) => new Chamber(dto))))))
      .subscribe((result: Chamber[]) => {
        this.updateTableData(result);
      }, error => {
        this.alertService.showMessage('Błąd podczas wczytywania danych', MessageType.Error);
      });
  }

  showExhibits(model) {
    this.chamberSelection.emit({ chamber: model });

  }  
  exportData(){
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'export.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

  ngOnInit() {
    this.reloadData();

    this.selection.changed.subscribe(next => {
      this.selectionChange.emit({ selection: this.selection });
    });
  }
}

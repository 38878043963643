import { Component, OnInit } from '@angular/core';
import { ServerManagementService } from '../../../../api/serverManagement.service';
import { SystemStatisticsDto } from '../../../../model/models';

@Component({
  selector: 'app-system-statistics',
  templateUrl: './system-statistics.component.html',
  styleUrls: ['./system-statistics.component.scss']
})
export class SystemStatisticsComponent implements OnInit {

  systemStatistics: SystemStatisticsDto;
  isLoading = true;

  constructor(private serverManagementService: ServerManagementService) { }

  ngOnInit() {
    this.serverManagementService.getSystemStatistics().subscribe(result => {
      this.systemStatistics = result;
      this.isLoading = false;
    });
  }

}

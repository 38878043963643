import { Component, OnInit, Inject } from '@angular/core';
import { RepositoryService, RepositoryDirectoryDto } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { RepositoryDirectory } from '../../../../models/repositoryDirectory';

@Component({
  selector: 'app-directory-creator-dialog',
  templateUrl: './directory-creator-dialog.component.html',
  styleUrls: ['./directory-creator-dialog.component.scss']
})
export class DirectoryCreatorDialogComponent implements OnInit {
  directoryName: string;
  constructor(public repositoryService: RepositoryService,
    private alertService: AlertService,
    public dialog: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  createDirectory() {
    const repoDir: RepositoryDirectoryDto = {
      name: this.directoryName,
      directory: this.data.directory,
      application: this.data.application,
      type: 'EMS.Models.RepositoryDirectory',
    };
    this.repositoryService
      .add(repoDir)
      .subscribe(result => {
        this.alertService.showMessage('Pomyślnie utworzono folder', MessageType.Info);
        this.dialog.close(true);
      }, error => {
        this.alertService.showMessage('Błąd podczas tworzenia folderu', MessageType.Error);
      });
  }

}

import { ScheduleTaskDto } from '../model/scheduleTaskDto';
import { Device } from './device';
import { TaskException } from '..';

export class ScheduleTask implements ScheduleTaskDto {
    constructor(scheduleTaskDto?: ScheduleTaskDto) {
        if (scheduleTaskDto == null) {
            return;
        }
        Object.assign(this, scheduleTaskDto);
        this.devices = scheduleTaskDto.devices.map(d => new Device(d));
    }
    id?: number;
    name?: string;
    action?: string;
    activeSince?: Date;
    activeUntil?: Date;
    actionTime?: string;
    devices?: Array<Device>;
    daysOfWeek?: Array<string>;
    exceptions?: Array<TaskException>;
}

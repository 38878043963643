import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DevicesService } from '../../../..';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from '../../../../models/device';

import * as flvjs from 'flv.js';
import { Player } from '../../../../models/player';

@Component({
  selector: 'app-desktop-view-dialog',
  templateUrl: './desktop-view-dialog.component.html',
  styleUrls: ['./desktop-view-dialog.component.scss']
})
export class DesktopViewDialogComponent implements AfterViewInit {

  constructor(
    private deviceService: DevicesService,
    public dialog: MatDialogRef<DesktopViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  device = new Device();
  loading = true;

  @ViewChild('player', { static: false }) player: ElementRef;

  ngAfterViewInit() {
    this.device = this.data.model;
    this.deviceService.callAction(this.device.id, 'RunOBS').subscribe(result => {
      this.runPlayer();
      this.loading = false;
    }, error => {
      this.runPlayer();
      this.loading = false;
    });

    this.dialog.beforeClosed().subscribe(r => {
      this.deviceService.callAction(this.device.id, 'StopOBS').subscribe(result => {

      });
    });
  }

  runPlayer(): void {
    const flvPlayer = flvjs.default.createPlayer({
      type: 'flv',
      url: 'http://' + location.hostname + ':8000/live/' + (this.device as Player).macAddress + '.flv'
    });
    flvPlayer.attachMediaElement(this.player.nativeElement);
    flvPlayer.load();
    flvPlayer.play();
  }

}

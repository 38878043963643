export * from './applications.service';
import { ApplicationsService } from './applications.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './backup.service';
import { BackupService } from './backup.service';
export * from './chambers.service';
import { ChambersService } from './chambers.service';
export * from './console.service';
import { ConsoleService } from './console.service';
export * from './devices.service';
import { DevicesService } from './devices.service';
export * from './exhibits.service';
import { ExhibitsService } from './exhibits.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './repository.service';
import { RepositoryService } from './repository.service';
export * from './scheduleTasks.service';
import { ScheduleTasksService } from './scheduleTasks.service';
export * from './serverManagement.service';
import { ServerManagementService } from './serverManagement.service';
export * from './unassignedDevices.service';
import { UnassignedDevicesService } from './unassignedDevices.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [ApplicationsService, AuthenticationService, BackupService, ChambersService, ConsoleService, DevicesService, ExhibitsService, GroupsService, RepositoryService, ScheduleTasksService, ServerManagementService, UnassignedDevicesService, UsersService];

import { Component, OnInit, Inject } from '@angular/core';
import { ApplicationsService } from '../../../..';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Application } from '../../../../models/application';
import { IncrementalStatisticsDto, TimespanStatisticsDto } from '../../../../model/models';

@Component({
  selector: 'app-statistics-dialog',
  templateUrl: './statistics-dialog.component.html',
  styleUrls: ['./statistics-dialog.component.scss']
})
export class StatisticsDialogComponent implements OnInit {

  constructor(
    private applicationService: ApplicationsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<StatisticsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  application = new Application();

  incrementalStatistics: IncrementalStatisticsDto[];
  timespanStatistics: TimespanStatisticsDto[];

  ngOnInit() {
    this.application = this.data.model;
    this.applicationService.getIncrementalStatistics(this.application.id).subscribe(result =>
      {
        this.incrementalStatistics = result;
      });
      this.applicationService.getTimespanStatistics(this.application.id).subscribe(result =>
        {
          this.timespanStatistics = result;
        });
  }


  export(): void {
    const data = JSON.stringify({ incremental: this.incrementalStatistics, timespan: this.timespanStatistics} );
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'Statistics.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

  downloadJson(){
    this.export();
  }

}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AlertService, MessageType } from '../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { Application } from '../../../models/application';
import { MatTableModule } from '@angular/material/table';
import { HttpEventType } from '@angular/common/http';
import { Form } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ApplicationDto } from '../../../model/models';
import { ApplicationsService } from '../../../api/applications.service';
import { ApplicationFormComponent } from './application-form/application-form.component';

@Component({
  selector: 'app-create-application-dialog',
  templateUrl: './create-application-dialog.component.html',
  styleUrls: ['./create-application-dialog.component.scss']
})
export class CreateApplicationDialogComponent implements OnInit {

  constructor(
    private applicationService: ApplicationsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CreateApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  application: Application = new Application();
  applicationCreated = false;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('formComponent', {static: false}) formComponent: ApplicationFormComponent;

  ngOnInit() {
  }

  createApp() {
    this.formComponent.form.control.markAllAsTouched();
    if (!this.formComponent.form.valid) {
      return;
    }

    const dto: ApplicationDto = {};
    Object.assign(dto, this.application);

    this.applicationService
      .add(dto)
      .pipe(map((applicationDto: ApplicationDto) => {
        const app: Application = new Application();
        Object.assign(app, applicationDto);
        return app;
      }))
      .subscribe((next) => {
        this.alertService.showMessage('Pomyślnie utworzono nową aplikację', MessageType.Info);
        this.application = next;
        this.applicationCreated = true;
        this.stepper.selected.completed = true;
        this.stepper.next();
      },
      (error) => {
        this.alertService.showMessage('Błąd podczas tworzenia aplikacji: ' + error.error, MessageType.Info);
      });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { Exhibit } from '../../../../models/exhibit';
import { ExhibitsService } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-exhibit-dialog',
  templateUrl: './edit-exhibit-dialog.component.html',
  styleUrls: ['./edit-exhibit-dialog.component.scss']
})
export class EditExhibitDialogComponent implements OnInit {

  exhibit = new Exhibit();

  constructor(
    private exhibitsService: ExhibitsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<EditExhibitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.exhibit = this.data.model;
  }

  updateExhibit() {
    this.exhibitsService
      .update(this.exhibit.id, this.exhibit)
      .subscribe((next) => {
        this.alertService.showMessage('Pomyślnie zaktualizowano stanowisko', MessageType.Info);
        this.dialog.close(true);
      },
      error => {
        this.alertService.showMessage('Wystąpił nieoczekiwany błąd', MessageType.Error);
      });
  }

}

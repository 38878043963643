import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { HttpEventType } from '@angular/common/http';
import { Form } from '@angular/forms';
import { DevicesService } from '../../../../api/devices.service';
import { AlertService } from '../../../../services/alert.service';
import { Device } from '../../../../models/device';

import * as flvjs from 'flv.js';

@Component({
  selector: 'app-view-device-dialog',
  templateUrl: './view-device-dialog.component.html',
  styleUrls: ['./view-device-dialog.component.scss']
})
export class ViewDeviceDialogComponent implements OnInit {
  constructor(
    private deviceService: DevicesService,
    private alertService: AlertService,
    public dialog: MatDialogRef<ViewDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  device = new Device();

  loading = true;

  ngOnInit(): void {
    this.deviceService.get(this.data.model.id).subscribe(result => {
      this.device = new Device(result);
      this.loading = false;
    });
  }

}

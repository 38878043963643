import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewChamberDialogComponent } from '../../dialogs/chamber/view-chamber-dialog/view-chamber-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { Chamber } from '../../../models/chamber';
import { ExhibitsViewComponent } from './exhibits-view/exhibits-view.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DeviceViewComponent } from './device-view/device-view.component';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('exhibits', {static: false}) exhibits: ExhibitsViewComponent;
  @ViewChild('devices', {static: false}) devices: DeviceViewComponent;

  selectedChamber: Chamber;
  selectedExhibit: Chamber;

  showExhibits($event = null) {
    if ($event == null) {
      this.selectedChamber = null;
      this.exhibits.reloadData(null);
    } else {
      this.selectedChamber = $event.chamber;
      this.exhibits.reloadData($event.chamber);
      this.stepper.next();
    }
  }

  showDevices($event = null) {
    if ($event == null) {
      this.selectedExhibit = null;
      this.devices.reloadData(null);
    } else {
      this.selectedExhibit = $event.exhibit;
      this.devices.reloadData($event.exhibit);
      this.stepper.next();
    }
  }

  onSelectionChange($event: StepperSelectionEvent) {
    $event.previouslySelectedStep.reset();

    if ($event.previouslySelectedIndex > $event.selectedIndex) {
      if ($event.previouslySelectedIndex === 1) {
        this.showExhibits(null);
      } else {
        this.showDevices(null);
      }
    }
  }

  ngOnInit() {

  }
}

import { DeviceDto } from '../model/deviceDto';
import { Exhibit } from './exhibit';
import { ExhibitDto } from '../model/exhibitDto';


export class Device implements DeviceDto {
  exhibitId?: number;
  exhibit: ExhibitDto;
  type?: string;
  id?: number;
  name: string;
  ipAddress?: string;
  status?: string;
  constructor(dto?: any) {
    if (dto != null) {
      Object.assign(this, dto);
    }
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { Chamber } from '../../../../models/chamber';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-chamber-dialog',
  templateUrl: './view-chamber-dialog.component.html',
  styleUrls: ['./view-chamber-dialog.component.scss']
})
export class ViewChamberDialogComponent implements OnInit {

  chamber = new Chamber();

  constructor(
    public dialog: MatDialogRef<ViewChamberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.chamber = this.data.model;
  }

}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService, MessageType } from './services/alert.service';
import { Injectable } from '@angular/core';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(public alertService: AlertService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return next.handle(request).pipe(
            tap(
                event => {
                },
                error => {
                    console.log(error);
                    if (error.status === 403) {
                        this.alertService.showMessage('Brak uprawnień do wykonania tej czynności', MessageType.Error);
                        return;

                    }
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 0) {
                            this.alertService.showMessage('Brak połączenia', MessageType.Error);
                        } else {
                            this.alertService.showMessage(error.error, MessageType.Error);
                        }
                    } else { this.alertService.showMessage('Nieoczekiwany błąd', MessageType.Error); }
                }));
    }

}

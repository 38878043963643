import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MessageType, AlertService } from '../../services/alert.service';
import { ApplicationsService, RepositoryService } from '../../api/api';

interface UploadFile extends File {
  progress: number;
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  constructor(private alertService: AlertService,
    private repositoryService: RepositoryService,
    private applicationsService: ApplicationsService) { }

  files: File[] = [];
  uploadedFiles: File[] = [];
  fileUploaded = false;
  progress: number;
  @Input() mode: 'single' | 'multiple' = 'single';

  isCompleted = false;

  displayedColumns = ['number', 'name', 'size', 'upload', 'operations'];
  dataSource: File[] = [];

  @Input()
  eventProvider: (files: File) => Observable<HttpEvent<any>>;

  @Output()
  uploadedFilesChange = new EventEmitter<File[]>();

  dragFiles() {
    const files = { ...this.files };
    this.files = [];
    this.upload(files);
  }

  fileChangeEvent($event){
    this.upload($event.target.files);
    console.log($event.target.files);
  }
 

  upload(files) {
    files = Object.keys(files).map(i => files[i] as UploadFile);
    let count = files.length;
    this.dataSource = files;
    files.forEach(file => {
      this.eventProvider(file)
        .subscribe(events => {
          if (events.type === HttpEventType.UploadProgress) {
            //this.progress = Math.round((100 * events.loaded) / events.total);
          } else if (events.type === HttpEventType.Response) {
            this.fileUploaded = true;
            count--;
            this.uploadedFiles = this.uploadedFiles.concat(files);
            this.uploadedFilesChange.emit(this.uploadedFiles);
            this.files = [];
            this.progress = Math.round(100);
            if (count <= 0) {
              this.repositoryService.generate().subscribe(result=>{
                this.alertService.showMessage('Pomyślnie wysłano pliki', MessageType.Info);

              })
            }
          }
        });
    });

  }

  parseFileSize(size) {
    const kb = size / 1000;
    const mb = kb / 1000;
    const gb = mb / 1000;
    return gb >= 1 ? gb.toFixed(3) + ' GB' : (mb >= 1 ? mb.toFixed(3) + ' MB' : (kb >= 1 ? kb.toFixed(3) + ' KB' : size + ' B'));
  }

  ngOnInit() {
  }

}

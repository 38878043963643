import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { LoginComponent } from './components/pages/login/login.component';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthorizationButtonComponent } from './components/pages/login/authorization-button/authorization-button.component';
import { LdapAuthenticationComponent } from './components/pages/login/ldap-authentication/ldap-authentication.component';
import { InternalAuthenticationComponent } from './components/pages/login/internal-authentication/internal-authentication.component';
import { HomeComponent } from './components/pages/home/home.component';
import { UsersComponent } from './components/pages/users/users.component';
import { GroupsComponent } from './components/pages/groups/groups.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { ScheduleComponent } from './components/pages/schedule/schedule.component';
import { UserWizardComponent } from './components/pages/users/user-wizard/user-wizard.component';
import { routing } from './app.routing';
import { GroupWizardComponent } from './components/pages/groups/group-wizard/group-wizard.component';
import { ApplicationsComponent } from './components/pages/applciations/applications.component';
import { ngfModule, ngf } from 'angular-file';
import { CreateApplicationDialogComponent } from './components/dialogs/application/create-application-dialog.component';
import { EditApplicationDialogComponent } from './components/dialogs/application/edit-application-dialog.component';
import { ViewApplicationDialogComponent } from './components/dialogs/application/view-application-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConfigEditorComponent } from './components/config-editor/config-editor.component';
import { Configuration, ConfigurationParameters } from './configuration';
import { ApiModule } from './modules/api.module';
import { ApplicationFormComponent } from './components/dialogs/application/application-form/application-form.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ApplicationVersionManagerComponent } from './components/application-version-manager/application-version-manager.component';
import { ApplicationConfigManagerComponent } from './components/application-config-manager/application-config-manager.component';
import { CreateDeviceDialogComponent } from './components/dialogs/device/create-device-dialog/create-device-dialog.component';
import { EditDeviceDialogComponent } from './components/dialogs/device/edit-device-dialog/edit-device-dialog.component';
import { ViewDeviceDialogComponent } from './components/dialogs/device/view-device-dialog/view-device-dialog.component';
import { DeviceFormComponent } from './components/dialogs/device/device-form/device-form.component';
import { DeviceViewComponent } from './components/pages/devices/device-view/device-view.component';
import { ExhibitsViewComponent } from './components/pages/devices/exhibits-view/exhibits-view.component';
import { CreateChamberDialogComponent } from './components/dialogs/chamber/create-chamber-dialog/create-chamber-dialog.component';
import { EditChamberDialogComponent } from './components/dialogs/chamber/edit-chamber-dialog/edit-chamber-dialog.component';
import { ViewChamberDialogComponent } from './components/dialogs/chamber/view-chamber-dialog/view-chamber-dialog.component';
import { ChambersViewComponent } from './components/pages/devices/chambers-view/chambers-view.component';
import { ChamberFormComponent } from './components/dialogs/chamber/chamber-form/chamber-form.component';
import { CreateExhibitDialogComponent } from './components/dialogs/exhibit/create-exhibit-dialog/create-exhibit-dialog.component';
import { ViewExhibitDialogComponent } from './components/dialogs/exhibit/view-exhibit-dialog/view-exhibit-dialog.component';
import { EditExhibitDialogComponent } from './components/dialogs/exhibit/edit-exhibit-dialog/edit-exhibit-dialog.component';
import { ExhibitFormComponent } from './components/dialogs/exhibit/exhibit-form/exhibit-form.component';
import { PlayerFormComponent } from './components/dialogs/device/player-form/player-form.component';
import { DesktopViewDialogComponent } from './components/dialogs/device/desktop-view-dialog/desktop-view-dialog.component';
import { ScheduleTaskFormComponent } from './components/dialogs/schedule/schedule-task-form/schedule-task-form.component';
// tslint:disable: max-line-length
import { CreateScheduleTaskDialogComponent } from './components/dialogs/schedule/create-schedule-task-dialog/create-schedule-task-dialog.component';
import { EditScheduleTaskDialogComponent } from './components/dialogs/schedule/edit-schedule-task-dialog/edit-schedule-task-dialog.component';
import { ViewScheduleTaskDialogComponent } from './components/dialogs/schedule/view-schedule-task-dialog/view-schedule-task-dialog.component';
import { DaysSelectorComponent } from './components/days-selector/days-selector.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MultilanguageVariblesEditorComponent } from './components/multilanguage-varibles-editor/multilanguage-varibles-editor.component';
import { EditMultilanguageVariblesDialogComponent } from './components/dialogs/multilanguage-varibles/edit-multilanguage-varibles-dialog/edit-multilanguage-varibles-dialog.component';
import { AdvancedVaribleEditorDialogComponent } from './components/dialogs/multilanguage-varibles/advanced-varible-editor-dialog/advanced-varible-editor-dialog.component';
import { RepositoryManagerComponent } from './components/repository-manager/repository-manager.component';
import { RepositoryComponent } from './components/pages/repository/repository.component';
import { FileUploadDialogComponent } from './components/dialogs/repository-manager/file-upload-dialog/file-upload-dialog.component';
import { DirectoryCreatorDialogComponent } from './components/dialogs/repository-manager/directory-creator-dialog/directory-creator-dialog.component';
import { FileRenameDialogComponent } from './components/dialogs/repository-manager/file-rename-dialog/file-rename-dialog.component';
import { FilePreviewDialogComponent } from './components/dialogs/repository-manager/file-preview-dialog/file-preview-dialog.component';
import { ModeratorsComponent } from './components/dialogs/exhibit/moderators/moderators.component';
import { UsersViewComponent } from './components/pages/users/users-view/users-view.component';
import { LdapAuthenticationService } from './api/ldapAuthentication.service';
import { AuthenticationService } from './api/api';
import { InternalAuthenticationService } from './api/internalAuthentication.service';
import { Interceptor } from './interceptor';
import { DiskUsageComponent } from './components/pages/home/disk-usage/disk-usage.component';
import { ChartsModule } from 'ng2-charts';
import { SystemStatisticsComponent } from './components/pages/home/system-statistics/system-statistics.component';
import { LoggerConsoleComponent } from './components/pages/home/logger-console/logger-console.component';
import { ProjectorFormComponent } from './components/dialogs/device/projector-form/projector-form.component';
import { DynamicFileReaderComponent } from './components/dynamic-file-reader/dynamic-file-reader.component';
import { BackupComponent } from './components/pages/backup/backup.component';
import { CreateBackupDialogComponent } from './components/dialogs/backup/create-backup-dialog/create-backup-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPolishPaginatorIntl } from './translators/polish-paginator-intl';
import { StatisticsDialogComponent } from './components/dialogs/application/statistics-dialog/statistics-dialog.component';
import { MapViewDialogComponent } from './components/dialogs/chamber/map-view-dialog/map-view-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MultiSearchSelectComponent } from './components/multi-search-select/multi-search-select.component';

export function getAdalConfig() {
    return {
        tenant: '0696073b-84ec-4df4-b9f2-c8a68e3e3727',
        clientId: '726a7bb6-4377-4c20-801f-41281ea00fb1',
        redirectUri: window.location.origin,
        endpoints: {
            'https://localhost:9220/api/': '726a7bb6-4377-4c20-801f-41281ea00fb1'
        },
        navigateToLoginRequestUrl: false,
        cacheLocation: 'localStorage',
        popUp: true
    };
}

export function apiConfigFactory(): Configuration {
    const params: ConfigurationParameters = {
        apiKeys: { 'Authorization': undefined },
        basePath: window.location.protocol + "//" + window.location.host

    };
    return new Configuration(params);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AuthorizationButtonComponent,
        LdapAuthenticationComponent,
        InternalAuthenticationComponent,
        HomeComponent,
        UsersComponent,
        GroupsComponent,
        DevicesComponent,
        ScheduleComponent,
        UserWizardComponent,
        GroupWizardComponent,
        ApplicationsComponent,
        CreateApplicationDialogComponent,
        EditApplicationDialogComponent,
        ViewApplicationDialogComponent,
        ConfigEditorComponent,
        ApplicationFormComponent,
        FileUploaderComponent,
        ApplicationVersionManagerComponent,
        ApplicationConfigManagerComponent,
        DevicesComponent,
        CreateDeviceDialogComponent,
        EditDeviceDialogComponent,
        ViewDeviceDialogComponent,
        DeviceFormComponent,
        DeviceViewComponent,
        ExhibitsViewComponent,
        ChambersViewComponent,
        CreateChamberDialogComponent,
        EditChamberDialogComponent,
        ViewChamberDialogComponent,
        ChamberFormComponent,
        CreateExhibitDialogComponent,
        ViewExhibitDialogComponent,
        EditExhibitDialogComponent,
        ExhibitFormComponent,
        PlayerFormComponent,
        DesktopViewDialogComponent,
        ScheduleTaskFormComponent,
        CreateScheduleTaskDialogComponent,
        EditScheduleTaskDialogComponent,
        ViewScheduleTaskDialogComponent,
        DaysSelectorComponent,
        MultilanguageVariblesEditorComponent,
        EditMultilanguageVariblesDialogComponent,
        AdvancedVaribleEditorDialogComponent,
        RepositoryManagerComponent,
        RepositoryComponent,
        FileUploadDialogComponent,
        DirectoryCreatorDialogComponent,
        FileRenameDialogComponent,
        FilePreviewDialogComponent,
        ModeratorsComponent,
        UsersViewComponent,
        DiskUsageComponent,
        SystemStatisticsComponent,
        LoggerConsoleComponent,
        ProjectorFormComponent,
        DynamicFileReaderComponent,
        BackupComponent,
        CreateBackupDialogComponent,
        StatisticsDialogComponent,
        MapViewDialogComponent,
        MultiSearchSelectComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MsAdalAngular6Module.forRoot(getAdalConfig),
        RouterModule.forRoot(routing),
        MaterialModule,
        BrowserAnimationsModule,
        ngfModule,
        ScrollingModule,
        ApiModule.forRoot(apiConfigFactory),
        NgxMaterialTimepickerModule,
        CKEditorModule,
        ChartsModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        AuthenticationGuard,
        LdapAuthenticationService,
        AuthenticationService,
        InternalAuthenticationService,
        {
            provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
        },
        { provide: MatPaginatorIntl, useValue: getPolishPaginatorIntl() }
    ],
    entryComponents: [
        UserWizardComponent,
        GroupWizardComponent,
        CreateApplicationDialogComponent,
        EditApplicationDialogComponent,
        ViewApplicationDialogComponent,
        CreateDeviceDialogComponent,
        EditDeviceDialogComponent,
        ViewDeviceDialogComponent,
        ChambersViewComponent,
        CreateChamberDialogComponent,
        ViewChamberDialogComponent,
        EditChamberDialogComponent,
        CreateExhibitDialogComponent,
        EditExhibitDialogComponent,
        ViewExhibitDialogComponent,
        CreateScheduleTaskDialogComponent,
        EditScheduleTaskDialogComponent,
        ViewScheduleTaskDialogComponent,
        DesktopViewDialogComponent,
        EditMultilanguageVariblesDialogComponent,
        AdvancedVaribleEditorDialogComponent,
        FileUploadDialogComponent,
        DirectoryCreatorDialogComponent,
        FileRenameDialogComponent,
        FilePreviewDialogComponent,
        ModeratorsComponent,
        DynamicFileReaderComponent,
        CreateBackupDialogComponent,
        StatisticsDialogComponent,
        MapViewDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

//git test
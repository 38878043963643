import { Component, OnInit, Inject, ElementRef, ViewChild, ApplicationRef, NgZone, AfterContentInit } from '@angular/core';
import { Chamber } from '../../../../models/chamber';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ExhibitsService, DevicesService } from '../../../../api/api';
import { map } from 'rxjs/operators';
import { Exhibit } from '../../../../models/exhibit';
import { HttpClient } from '@angular/common/http';
import { Device } from '../../../../models/device';
import { debug } from 'util';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { EditExhibitDialogComponent } from '../../exhibit/edit-exhibit-dialog/edit-exhibit-dialog.component';
import { ModeratorsComponent } from '../../exhibit/moderators/moderators.component';
import { ViewExhibitDialogComponent } from '../../exhibit/view-exhibit-dialog/view-exhibit-dialog.component';



@Component({
  selector: 'app-map-view-dialog',
  templateUrl: './map-view-dialog.component.html',
  styleUrls: ['./map-view-dialog.component.scss']
})
export class MapViewDialogComponent implements AfterContentInit {

  chamber = new Chamber();
  exhibits: Exhibit[];
  devices: Device[];
  chamberData: any;
  loading = true;

  @ViewChild('map', {static: false})
  mapImage: ElementRef;

  constructor(private zone: NgZone,
    public dialog: MatDialogRef<MapViewDialogComponent>,
    public exhibitsService: ExhibitsService,
    public devicesService: DevicesService,
    public alertService: AlertService,
    private http: HttpClient,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { }


    getChamberJson(chamber) {
      this.http.get('assets/' + chamber + '.json').subscribe(data => {
        this.chamberData = data;
        this.exhibitsService
          .getAll(this.chamber != null ? this.chamber.id : null)
          .pipe(map(((dtos) => dtos.map(((dto) => new Exhibit(dto))))))
          .subscribe((result: Exhibit[]) => {
            this.exhibits = result;
          });
       });

       this.devicesService
       .getAll()
       .pipe(map(((dtos) => dtos.map(((dto) => new Device(dto))))))
       .subscribe((result: Device[]) => {
         this.devices = result;
       });

  }

  action(model, action) {
    this.devicesService.getAll(null, model.id).subscribe(devices => devices.forEach(device => {
      this.devicesService.callAction(device.id, action).subscribe(result => {
        this.alertService.showMessage('Akcja wywołana poprawnie', MessageType.Info);
      }, error => {
        this.alertService.showMessage('Błąd podczas wywoływania akcji', MessageType.Error);
        console.error(error);
      });
    }));
  }

  imgLoaded() {
    this.loading = false;

  }

  getTop(exhibit) {
    return (this.chamberData[exhibit.name].position.y *
      (this.mapImage.nativeElement.width / 975)) + this.mapImage.nativeElement.getBoundingClientRect().top;
  }
  getLeft(exhibit) {
    return (this.chamberData[exhibit.name].position.x *
      (this.mapImage.nativeElement.width / 975)) + this.mapImage.nativeElement.getBoundingClientRect().left;
  }
  editDialog(model) {
    const dialogRef = this.dialogRef.open(EditExhibitDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } },
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reload();
      }
    });
  }

moderatorsDialog(model) {
  const dialogRef = this.dialogRef.open(ModeratorsComponent,
    {
      width: '600px',
      data: { model: { ...model } },
    });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result) {
      this.reload();
    }
  });
}


  viewDialog(model) {
    const dialogRef = this.dialogRef.open(ViewExhibitDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } }
      });
  }

  isExhibitOnline(exhibit) {
    const exDevices = this.devices.filter(x => x.exhibit != null && x.exhibit.id === exhibit.id);
    const activeDevices = exDevices.filter(x => x.status === 'Active');

    if (exDevices.length === activeDevices.length) {
      return true;
    }
    return false;
  }

  reload() {
    this.getChamberJson("m_"+this.chamber.id);
  }

  ngAfterContentInit () {
    this.chamber = this.data.model;
    this.reload();
  }

}

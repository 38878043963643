import { Component, OnInit } from '@angular/core';
import { BackupService } from '../../../api/backup.service';
import { ExtendedTableComponent } from '../../../helpers/extended-management';
import { CreateBackupDialogComponent } from '../../dialogs/backup/create-backup-dialog/create-backup-dialog.component';
import { AlertService, MessageType } from '../../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent extends ExtendedTableComponent implements OnInit {

  displayedColumns = ['select', 'name'];
  constructor(
      private alertService: AlertService,
      private backupService: BackupService,
      private dialog: MatDialog
  ) {
      super();
  }

  openDialog() {
      const dialogRef = this.dialog.open(CreateBackupDialogComponent,
          {
              width: '500px'
          });

      dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
              this.reloadData();
          }
      });
  }

  reloadData() {
      this.loading = true;
      this.backupService.getBackups().subscribe(result => {
        this.updateTableData(result);
      }, error => {this.updateTableData([]); });
  }

  ngOnInit() {
      this.reloadData();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { RepositoryService } from '../../../..';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryObjectDto } from '../../../../model/models';

@Component({
  selector: 'app-file-rename-dialog',
  templateUrl: './file-rename-dialog.component.html',
  styleUrls: ['./file-rename-dialog.component.scss']
})
export class FileRenameDialogComponent implements OnInit {

  newName = '';

  constructor(public repositoryService: RepositoryService,
    private alertService: AlertService,
    public dialog: MatDialogRef<FileRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  renameFile() {
    const re: RepositoryObjectDto = {
      application: this.data.repositoryObject.application,
      directory: this.data.repositoryObject.dire,
      name: this.newName,
      id: this.data.repositoryObject.id
    };
    this.repositoryService.update(re.id, re).subscribe(() => {
      this.dialog.close(true);
    });
  }

  ngOnInit(): void {
    this.newName = this.data.repositoryObject.name;
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { Exhibit } from '../../../../models/exhibit';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-exhibit-dialog',
  templateUrl: './view-exhibit-dialog.component.html',
  styleUrls: ['./view-exhibit-dialog.component.scss']
})
export class ViewExhibitDialogComponent implements OnInit {

  exhibit = new Exhibit();

  constructor(
    public dialog: MatDialogRef<ViewExhibitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.exhibit = this.data.model;
  }


}

import { Component, OnInit, Input } from '@angular/core';
import { ApplicationConfigurationDto } from '../..';
import { MessageType, AlertService } from '../../services/alert.service';
import { ApplicationConfiguration } from '../../models/applicationConfiguration';
import { ApplicationsService } from '../../api/api';
import { Application } from '../../models/application';
import { MatExpansionPanelDescription } from '@angular/material/expansion';
import { DynamicFileReaderComponent } from '../dynamic-file-reader/dynamic-file-reader.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-application-config-manager',
  templateUrl: './application-config-manager.component.html',
  styleUrls: ['./application-config-manager.component.scss']
})
export class ApplicationConfigManagerComponent implements OnInit {

  @Input() application: Application;

  configs: ApplicationConfigurationDto[];

  disableEditMode = false;

  constructor(private applicationsService: ApplicationsService,
    private dialog: MatDialog,
    private alertService: AlertService) { }

  ngOnInit() {
    this.reloadConfigs();
  }
  
  import() {
    const dialogRef = this.dialog.open(DynamicFileReaderComponent,
      {
        width: '700px',
      });

    dialogRef.afterClosed().subscribe(result => {
      const dto: ApplicationConfigurationDto = {
        name: 'Konfiguracja importowana',
        configuration: JSON.parse(result)
      };
  
      this.applicationsService.addConfiguration(dto, this.application.id)
      .subscribe(next => {
        this.alertService.showMessage('Dodano nową konfigurację', MessageType.Info);
        this.reloadConfigs();
      });


    });
  }

  addConfig() {
    const dto: ApplicationConfigurationDto = {
      name: 'Nowa konfiguracja',
      configuration: {'Zmienna 1': 'Wartość'}
    };

    this.applicationsService.addConfiguration(dto, this.application.id)
    .subscribe(next => {
      this.alertService.showMessage('Dodano nową konfigurację', MessageType.Info);
      this.reloadConfigs();
    });
  }

  onDelete($event) {
    this.applicationsService.removeConfiguration($event.config.id).subscribe(next => {
      this.reloadConfigs();
    }, error => {
      this.alertService.showMessage('Błąd podczas usuwania konfiguracji', MessageType.Error);
    });
  }

  onSave($event) {
    console.log($event);
    $event.config.updateDictionary();
    const dto: ApplicationConfigurationDto = {
      applicationId: this.application.id,
      configuration: $event.config.configuration,
      id: $event.config.id,
      name: $event.config.name
    };
    console.log(dto);
    this.applicationsService.updateConfiguration($event.config.id, dto).subscribe(next => {
      this.alertService.showMessage('Pomyślnie zaktualizowano konfigurację', MessageType.Info);
      this.reloadConfigs();
    },
    error => {
      this.alertService.showMessage('Błąd podczas aktualizacji konfiguracji', MessageType.Info);
    });
  }

  reloadConfigs() {
    this.applicationsService.getConfigurations(this.application.id)
    .subscribe(next => {
      this.configs = next.map((dto: ApplicationConfigurationDto) =>
        new ApplicationConfiguration(dto));
    });
  }

  configChange(config: ApplicationConfiguration) {
    this.application.config = config;
  }

  importConfig() {
    throw new Error('Not implemented yet');
  }
}

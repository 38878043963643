import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApplicationConfiguration } from '../../models/applicationConfiguration';
import { Configuration } from '../../configuration';
import { config } from 'process';

@Component({
  selector: 'app-config-editor',
  templateUrl: './config-editor.component.html',
  styleUrls: ['./config-editor.component.scss']
})
export class ConfigEditorComponent implements OnInit {

  @Input()
  config: ApplicationConfiguration;

  @Output()
  deleteEvent = new EventEmitter<any>();

  @Output()
  saveEvent = new EventEmitter<any>();

  configVaribleTypes = [{name: 'number', label: 'Liczba'}, {name: 'string', label: 'Tekst'}, 
  {name: 'boolean', label: 'Warunek'}, {name: 'object', label: 'Tablica'}];

  constructor() { }

  typeof(val) {
    // tslint:disable-next-line: triple-equals
    if (parseInt(val, 10) == val) {
      return 'number';
    }
    return typeof val;
  }

  addConfigVarible() {
    this.config.values.push({key: '', value: ''});
    this.config.updateDictionary();
  }

  deleteConfigVarible(varible) {
    const index = this.config.values.indexOf(varible);
    this.config.values.splice(index, 1);
    this.config.updateDictionary();
  }

  saveChanges() {
    this.saveEvent.emit({config: this.config});
  }

  deleteConfig() {
    this.deleteEvent.emit({config: this.config});
  }

  ngOnInit() {
  }

}

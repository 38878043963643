import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DevicesService } from '../../../../api/devices.service';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { Device } from '../../../../models/device';
import { DeviceDto } from '../../../../model/models';
import { ApplicationsService } from '../../../..';
import { Application } from '../../../../models/application';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-device-dialog',
  templateUrl: './edit-device-dialog.component.html',
  styleUrls: ['./edit-device-dialog.component.scss']
})
export class EditDeviceDialogComponent implements OnInit {

  device = new Device();
  applications: Application[];

  constructor(
    private deviceService: DevicesService,
    private alertService: AlertService,
    private applicationsService: ApplicationsService,
    public dialog: MatDialogRef<EditDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
    this.applicationsService.getAll()
    .pipe(map(dtos => dtos.map(dto => new Application(dto))))
    .subscribe(result => {
      this.applications = result;
    });
    this.device = this.data.model;
  }

  save() {
    this.deviceService
      .update(this.device.id, this.device)
      .subscribe(next => {
        this.alertService.showMessage('Pomyślnie zaktualizowano urządzenie', MessageType.Info);
        this.dialog.close(true);
      },
      error => {
        this.alertService.showMessage('Wystąpił nieoczekiwany błąd', MessageType.Error);
      });
  }
  
  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
}

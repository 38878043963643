import { Component, OnInit, Input, Inject } from '@angular/core';
import { RepositoryService } from '../../../../api/api';
import { RepositoryFileDto } from '../../../../model/models';
import { Observable, Observer } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { RepositoryDirectory } from '../../../../models/repositoryDirectory';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'rxjs/add/operator/concatMap';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  public static Instance: FileUploadDialogComponent;
  constructor(public repositoryService: RepositoryService,
    private alertService: AlertService,
    public dialog: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { FileUploadDialogComponent.Instance = this; }

  @Input() directory: RepositoryDirectory;

  ngOnInit() {

  }


  sendFiles(file: any): Observable<HttpEvent<any>> {
    let base: string = FileUploadDialogComponent.Instance.data.directory;
    if ((!base.endsWith('/')) && base.length > 1) {
      base += '/';
    }

    let dir = base +
      file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/'));
    if (dir.endsWith("/")) {
      dir = dir.substring(0, dir.length - 1);
    }

    const repoFile: RepositoryFileDto = {
      name: file.name,
      directory: dir,
      application: FileUploadDialogComponent.Instance.data.application,
      type: 'EMS.Models.RepositoryFile',
      size: file.size
    };
    return this.repositoryService
      .add(repoFile).concatMap(r => this.repositoryService.upload(r.id, file, 'events', true));


  }
}

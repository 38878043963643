import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DevicesService, ChambersService, ExhibitsService } from '../../../../api/api';
import { Exhibit } from '../../../../models/exhibit';
import { map } from 'rxjs/operators';
import { Chamber } from '../../../../models/chamber';
import { Device } from '../../../../models/device';
import { NgForm } from '@angular/forms';
import { ScheduleTask } from '../../../../models/scheduleTask';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-schedule-task-form',
  templateUrl: './schedule-task-form.component.html',
  styleUrls: ['./schedule-task-form.component.scss']
})
export class ScheduleTaskFormComponent implements OnInit {
  @Input() public scheduleTask: ScheduleTask;
  @Input() readonly: boolean;
  @ViewChild('form', { static: true }) public form: NgForm;
  @ViewChild('selectedDaysOfWeek', { static: true }) public selectedDaysOfWeek: MatSelectionList;

  scheduleActions =
    [
      { name: 'Włącz urządzenia', value: 'TurnOn' },
      { name: 'Wyłącz urządzenia', value: 'Shutdown' },
      { name: 'Restartuj urządzenia', value: 'Restart' }
    ];


  chambers: Chamber[];
  exhibits: Exhibit[];
  devices: Device[];

  // selectedChambers: Chamber[] = [];
  
  // public filteredChambers: ReplaySubject<Chamber[]> = new ReplaySubject<Chamber[]>(1);

  constructor(
    private devicesService: DevicesService,
    private chambersService: ChambersService,
    private exhibitsService: ExhibitsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<ScheduleTaskFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

     }

  ngOnInit() {
    this.devicesService
      .getAll()
      .pipe(map(dtos => dtos.map(dto => new Device(dto))))
      .subscribe(result => { this.devices = result; });

    this.exhibitsService
      .getAll()
      .pipe(map(dtos => dtos.map(dto => new Exhibit(dto))))
      .subscribe(result => { this.exhibits = result; });

    this.chambersService
      .getAll()
      .pipe(map(dtos => dtos.map(dto => new Chamber(dto))))
      .subscribe(result => {
        this.chambers = result;
        //this.filteredChambers.next(this.chambers.slice());
      });

  }


  setActionTime($event){
    this.scheduleTask.actionTime = $event;
  }

  addException(){
    this.scheduleTask.exceptions.push({day: null, hour: null, month: null, year: null });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { UsersService } from '../../../../api/users.service';
import { GroupsService } from '../../../../api/groups.service';
import { Group } from '../../../../models/group';
import { User } from '../../../../models/user';

@Component({
  selector: 'app-user-wizard',
  templateUrl: './user-wizard.component.html',
  styleUrls: ['./user-wizard.component.scss']
})
export class UserWizardComponent implements OnInit {

  password: string;
  hidePassword = true;
  sendMail = false;

  userGroups: Group[];
  user: User;

  constructor(private userService: UsersService,
    private groupService: GroupsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<UserWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

  }

  ngOnInit() {
    this.groupService.getAll().subscribe(value => { this.userGroups = value; });
    this.user = this.data.user === undefined ? new User() : this.data.user;
  }

  valueChange($event){
    this.sendMail = $event.checked;
    console.log(this.sendMail)
  }

  onSubmit(form): void {
    console.log(this.sendMail);
    switch (this.data.mode) {
      case 'create':
        this.userService.add(this.user, this.password === '' ? null : this.password, this.sendMail).subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie dodano użytkownika', MessageType.Info);
          this.dialog.close(true);
        });
        break;
      case 'edit':
        this.userService.update(this.data.user.id, this.user, this.password === '' ? null : this.password, this.sendMail).subscribe((result: any) => {
          this.alertService.showMessage('Pomyślnie zaktualizowano dane użytkownika', MessageType.Info);
          this.dialog.close(true);
        });
        break;
    }

  }

}

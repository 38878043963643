import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-chamber-form',
  templateUrl: './chamber-form.component.html',
  styleUrls: ['./chamber-form.component.scss']
})
export class ChamberFormComponent {

  @Input() chamber: any;

  @Input() readonly: boolean;

  @ViewChild('form', {static: true}) public form: NgForm;

  constructor() { }


}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Exhibit } from '../../../../models/exhibit';
import { ExhibitsService, ExhibitDto } from '../../../..';
import { map } from 'rxjs/operators';
import { Player } from '../../../../models/player';

@Component({
  selector: 'app-player-form',
  templateUrl: './player-form.component.html',
  styleUrls: ['./player-form.component.scss']
})
export class PlayerFormComponent implements OnInit {
  @Input() public player: Player;
  @Input() readonly: boolean;
  @ViewChild('form', {static: true}) public form: NgForm;

  constructor(private exhibitsService: ExhibitsService) { }

  ngOnInit() {
    
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { ChambersService } from '../../../../api/api';
import { ChamberDto } from '../../../..';
import { map } from 'rxjs/operators';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { Chamber } from '../../../../models/chamber';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-chamber-dialog',
  templateUrl: './create-chamber-dialog.component.html',
  styleUrls: ['./create-chamber-dialog.component.scss']
})
export class CreateChamberDialogComponent implements OnInit {

  chamber: Chamber = new Chamber();

  constructor(
    private chambersService: ChambersService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CreateChamberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  createChamber() {
    this.chambersService
    .add(this.chamber)
    .pipe(map((chamberDto: ChamberDto) => new Chamber(chamberDto)))
    .subscribe((next) => {
      this.alertService.showMessage('Pomyślnie utworzono wirtualną salę', MessageType.Info);
      this.chamber = next;
      this.dialog.close(true);
    },
    (error) => {
      this.alertService.showMessage('Błąd podczas tworzenia wirtualnej sali: ' + error.error, MessageType.Info);
    });
  }
}

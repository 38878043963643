import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClient } from '@angular/common/http';


import { ApplicationsService } from '../api/applications.service';
import { AuthenticationService } from '../api/authentication.service';
import { BackupService } from '../api/backup.service';
import { ChambersService } from '../api/chambers.service';
import { ConsoleService } from '../api/console.service';
import { DevicesService } from '../api/devices.service';
import { ExhibitsService } from '../api/exhibits.service';
import { GroupsService } from '../api/groups.service';
import { RepositoryService } from '../api/repository.service';
import { ScheduleTasksService } from '../api/scheduleTasks.service';
import { ServerManagementService } from '../api/serverManagement.service';
import { UnassignedDevicesService } from '../api/unassignedDevices.service';
import { UsersService } from '../api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApplicationsService,
    AuthenticationService,
    BackupService,
    ChambersService,
    ConsoleService,
    DevicesService,
    ExhibitsService,
    GroupsService,
    RepositoryService,
    ScheduleTasksService,
    ServerManagementService,
    UnassignedDevicesService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ScheduleTasksService } from '../../../api/scheduleTasks.service';
import { AlertService, MessageType } from '../../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ExtendedTableComponent } from '../../../helpers/extended-management';
// tslint:disable-next-line: max-line-length
import { CreateScheduleTaskDialogComponent } from '../../dialogs/schedule/create-schedule-task-dialog/create-schedule-task-dialog.component';
import { EditScheduleTaskDialogComponent } from '../../dialogs/schedule/edit-schedule-task-dialog/edit-schedule-task-dialog.component';
import { ViewScheduleTaskDialogComponent } from '../../dialogs/schedule/view-schedule-task-dialog/view-schedule-task-dialog.component';
import { ScheduleTask } from '../../../models/scheduleTask';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent extends ExtendedTableComponent implements OnInit  {

  displayedColumns = ['select', 'name', 'details'];
  constructor(
      private alertService: AlertService,
      private scheduleTaskService: ScheduleTasksService,
      private dialog: MatDialog
  ) {
      super();
  }

  createDialog() {
      const dialogRef = this.dialog.open(CreateScheduleTaskDialogComponent,
          {
              width: '800px'
          });

      dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
              this.reloadData();
          }
      });
  }

  editDialog(model) {
      const dialogRef = this.dialog.open(EditScheduleTaskDialogComponent,
          {
              width: '800px',
              data: { model: { ...model } }
          });

      dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
              this.reloadData();
          }
      });
  }

  viewDialog(model) {
      const dialogRef = this.dialog.open(ViewScheduleTaskDialogComponent,
          {
              width: '700px',
              data: { model: { ...model } }
          });
  }

  deleteSelected() {
      this.selection.selected.forEach(element => {
          this.scheduleTaskService.remove(element.id).subscribe(next => {
          }, error => {
              this.alertService.showMessage('Wystąpił błąd podczas usuwania harmonogramu: ' + error.error, MessageType.Error);
          }, () => {
              this.alertService.showMessage('Pomyślnie usunięto harmonogram', MessageType.Info);
              this.selection.clear();
              this.reloadData();
          });
      });
  }
  exportData(){
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'export.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}
  reloadData() {
      this.loading = true;
      this.scheduleTaskService.getAll().subscribe(
          (result: ScheduleTask[]) => {
              this.updateTableData(result);
          });
  }

  ngOnInit() {
      this.reloadData();
  }
}

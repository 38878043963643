/**
 * EMS API
 * Exposition Management System API Documentation
 *
 * OpenAPI spec version: v2
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LogEntryDto { 
    id?: number;
    message?: string;
    stackTrace?: string;
    date?: Date;
    source?: string;
    type?: LogEntryDto.TypeEnum;
}
export namespace LogEntryDto {
    export type TypeEnum = 0 | 1 | 2;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum
    };
}
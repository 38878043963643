import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AlertService, MessageType } from '../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Application } from '../../../models/application';
import { ApplicationsService } from '../../../api/applications.service';
import { ApplicationDto } from '../../../model/applicationDto';

@Component({
  selector: 'app-edit-application-dialog',
  templateUrl: './edit-application-dialog.component.html',
  styleUrls: ['./edit-application-dialog.component.scss']
})
export class EditApplicationDialogComponent implements OnInit {

  constructor(
    private applicationService: ApplicationsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<EditApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  application = new Application();

  ngOnInit() {
    this.application = this.data.model;
  }

  save() {
    this.applicationService
      .update(this.application.id, this.application)
      .subscribe((next) => {
        this.alertService.showMessage('Pomyślnie zaktualizowano aplikację', MessageType.Info);
        this.dialog.close(true);
      },
      error => {
        this.alertService.showMessage('Wystąpił nieoczekiwany błąd', MessageType.Error);
      });
  }
}

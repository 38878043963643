import { PermissionDto, GroupDto } from '../model/models';
import { Permission } from './permission';

export class Group implements GroupDto {
 
  public constructor(group?: GroupDto) {
    if (group == null) {
        return;
    }
    this.id = group.id;
    this.name = group.name;
    this.permissions = group.permissions.map(p => new Permission(p));
  }
  id?: number;
  name?: string;
  permissions?: Array<Permission>;
}

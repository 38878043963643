import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { DevicesService } from '../../../..';
import { MatDialog } from '@angular/material/dialog';
import { CreateDeviceDialogComponent } from '../../../dialogs/device/create-device-dialog/create-device-dialog.component';
import { EditDeviceDialogComponent } from '../../../dialogs/device/edit-device-dialog/edit-device-dialog.component';
import { ViewDeviceDialogComponent } from '../../../dialogs/device/view-device-dialog/view-device-dialog.component';
import { map } from 'rxjs/operators';
import { Device } from '../../../../models/device';
import { Exhibit } from '../../../../models/exhibit';
import { ExhibitsService } from '../../../../api/api';
import { DesktopViewDialogComponent } from '../../../dialogs/device/desktop-view-dialog/desktop-view-dialog.component';


@Component({
  selector: 'app-device-view',
  templateUrl: './device-view.component.html',
  styleUrls: ['./device-view.component.scss']
})
export class DeviceViewComponent extends ExtendedTableComponent implements OnInit, OnDestroy {

  @Input() readonly = false;
  @Output() selectionChange = new EventEmitter();
  @Input() selectedDevices: Device[] = [];

  intervalId: any;

  displayedColumns = ['select', 'name', 'description', 'exhibit', 'status', 'details'];
  constructor(
    private alertService: AlertService,
    private devicesService: DevicesService,
    private exhibitsService: ExhibitsService,
    private dialog: MatDialog
  ) {
    super();
  }

  createDialog() {
    const dialogRef = this.dialog.open(CreateDeviceDialogComponent,
      {
        width: '600px'
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  editDialog(model) {
    const dialogRef = this.dialog.open(EditDeviceDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } },
        panelClass: 'no-border-dialog-container'
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  viewDialog(model) {
    const dialogRef = this.dialog.open(ViewDeviceDialogComponent,
      {
        width: '900px',
        data: { model: { ...model } },
      });
  }

  viewDesktopDialog(model) {
    const dialogRef = this.dialog.open(DesktopViewDialogComponent,
      {
        width: '900px',
        data: { model: { ...model } },
      });
  }

  action(model, action) {
    this.devicesService.callAction(model.id, action).subscribe(result => {
      this.alertService.showMessage('Akcja wywołana poprawnie', MessageType.Info);
    }, error => {
      this.alertService.showMessage('Błąd podczas wywoływania akcji', MessageType.Error);
      console.error(error);
    });
  }

  deleteSelected() {
    this.selection.selected.forEach(element => {
      this.devicesService.remove(element.id).subscribe(next => {
      }, error => {
        this.alertService.showMessage('Wystąpił błąd podczas usuwania aplikacji: ' + error.error, MessageType.Error);
      }, () => {
        this.alertService.showMessage('Pomyślnie usunięto urządzenie', MessageType.Info);
        this.selection.clear();
        this.reloadData();
      });
    });
  }

  public reloadData(exhibitFilter: Exhibit = null) {

    this.loading = true;
    this.devicesService
      .getAll(null, exhibitFilter != null ? exhibitFilter.id : null)
      .pipe(map(((dtos) => dtos.map(((dto) => new Device(dto))))))
      .subscribe((result: Device[]) => {
        this.updateTableData(result);
        if(this.selectedDevices!=null){
        this.selectedDevices.forEach(device => {
          this.selection.select(this.dataSource.data.find(f => f.name === device.name));
        });
      }
      }, error => {
        this.alertService.showMessage('Błąd podczas wczytywania danych', MessageType.Error);
      });

  }

  exportData(){
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'export.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

  ngOnDestroy() {

  }

  ngOnInit() {
    this.reloadData();
    this.selection.changed.subscribe(next => {
      this.selectionChange.emit({selection: this.selection});
    });
  }
}

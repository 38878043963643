import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Router } from '@angular/router';
import { routing } from './app.routing';
import { ApiModule } from './modules/api.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EMS';
  userName: string;
  clientName = 'Centrum Nowoczesności Młyn Wiedzy';
  menuRoutes = routing;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.userName = 'Super User';
  }
}

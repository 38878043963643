import { ApplicationConfigurationDto } from '../model/applicationConfigurationDto';

export class ApplicationConfiguration implements ApplicationConfigurationDto {
    public id: number;
    public name: string;
    public configuration: { [key: string]: any; };
    public values = [];

    constructor(dto?: ApplicationConfigurationDto) {
        if (dto != null) {
            this.id = dto.id;
            this.name = dto.name;
            this.configuration = dto.configuration;
            this.updateValues();
        }
    }

    updateDictionary() {
        this.configuration = {};
        this.values.forEach(element => {
            this.configuration[element.key] = element.value;
        });
    }

    updateValues() {
        this.values = [];
        // tslint:disable-next-line: forin
        for (const key in this.configuration) {
            const value = this.configuration[key];
            this.values.push({key: key, value: value});
        }
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, MessageType } from '../../../services/alert.service';
import { ExtendedTableComponent } from '../../../helpers/extended-management';
import { Application } from '../../../models/application';
import { CreateApplicationDialogComponent } from '../../dialogs/application/create-application-dialog.component';
import { EditApplicationDialogComponent } from '../../dialogs/application/edit-application-dialog.component';
import { ViewApplicationDialogComponent } from '../../dialogs/application/view-application-dialog.component';
import { ApplicationsService } from '../../../api/applications.service';
import { MultilanguageVariblesEditorComponent } from '../../multilanguage-varibles-editor/multilanguage-varibles-editor.component';
import { EditMultilanguageVariblesDialogComponent } from '../../dialogs/multilanguage-varibles/edit-multilanguage-varibles-dialog/edit-multilanguage-varibles-dialog.component';
import { DynamicFileReaderComponent } from '../../dynamic-file-reader/dynamic-file-reader.component';
import { MultilanguageVarible } from '../../../models/multilanguageVarible';
import { StatisticsDialogComponent } from '../../dialogs/application/statistics-dialog/statistics-dialog.component';

@Component({
    selector: 'app-applicatons',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent extends ExtendedTableComponent implements OnInit {
    constructor(
        private alertService: AlertService,
        private applicationService: ApplicationsService,
        private dialog: MatDialog
    ) {
        super();
    }

    displayedColumns = ['select', 'name', 'details'];
    currentData: MultilanguageVarible[];
    currentMode: "pl" | "en";

    createDialog() {
        const dialogRef = this.dialog.open(CreateApplicationDialogComponent,
            {
                width: '800px'
            });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.reloadData();
            }
        });
    }

    editDialog(model) {
        const dialogRef = this.dialog.open(EditApplicationDialogComponent,
            {
                width: '800px',
                data: { model: { ...model } },
                panelClass: 'no-border-dialog-container'
            });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.reloadData();
            }
        });
    }

    multilanguageTextEditorDialog(model) {
        const dialogRef = this.dialog.open(EditMultilanguageVariblesDialogComponent,
            {
                width: '1600px',
                data: { model: { ...model } },
            });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.reloadData();
            }
        });
    }

    viewDialog(model) {
        const dialogRef = this.dialog.open(ViewApplicationDialogComponent,
            {
                width: '700px',
                data: { model: { ...model } }
            });
    }

    statDialog(model) {
        const dialogRef = this.dialog.open(StatisticsDialogComponent,
            {
                width: '700px',
                data: { model: { ...model } }
            });
    }

    deleteSelected() {
        this.selection.selected.forEach(element => {
            this.applicationService.remove(element.id).subscribe(next => {
            }, error => {
                this.alertService.showMessage('Wystąpił błąd podczas usuwania aplikacji: ' + error.error, MessageType.Error);
            }, () => {
                this.alertService.showMessage('Pomyślnie usunięto aplikacje', MessageType.Info);
                this.selection.clear();
                this.reloadData();
            });
        });
    }
    import(mode: "pl" | "en") {
        const dialogRef = this.dialog.open(DynamicFileReaderComponent,
            {
                width: '700px',
            });
        this.currentMode = mode;
        dialogRef.afterClosed().subscribe(result => {
            this.selection.selected.forEach(element => {
                this.applicationService.get(element.id).subscribe(app => {
                    this.currentData = app.multilanguageVaribles;
                    this.getVaribles(JSON.parse(result));
                    app.multilanguageVaribles = this.currentData;
                    this.applicationService.update(app.id, app).subscribe(result => {
                        this.alertService.showMessage("Zaktualizowano pomyślnie", MessageType.Info);
                    });
                });
            });
        });
    }

    getVaribles(obj, currentPath = '') {
        const keys = Object.keys(obj);
        keys.forEach(key => {
            if ((typeof obj[key]) === 'object' && !Array.isArray(obj[key])) {
                const cPath = currentPath + key + '.';
                this.getVaribles(obj[key], cPath);
            } else if (Array.isArray(obj[key])) {
                obj[key].forEach((element, index) => {
                    const cPath = currentPath + key + '[' + index + ']';
                    if ((typeof element) === 'object' && !Array.isArray(element)) {
                        this.getVaribles(element, cPath + '.');
                    } else {
                        if (Array.isArray(element)) {
                            alert('Błąd (Array In Array)');
                            element.forEach((innerElement, innerIndex) => {
                                const cPathx = cPath + '[' + innerIndex + '].';
                                this.getVaribles(innerElement, cPathx);
                            });
                        } else {
                            this.addValueFromImport(cPath, element);
                        }
                    }
                });
            } else {
                this.addValueFromImport(currentPath + key, obj[key]);
            }
        });
    }

    addValueFromImport(name, val) {
        const f = this.currentData.find(p => p.name === name);
        if (f != null) {
            f.multilanguageValuesDto.find(mv => mv.language === this.currentMode).value = val;
        } else {

            this.currentData.push(
                {
                    name: name,
                    multilanguageValuesDto:
                        [
                            { language: 'pl', value: this.currentMode === 'pl' ? val : '' },
                            { language: 'en', value: this.currentMode === 'en' ? val : '' },
                        ]
                });
        }
    }

    reloadData() {
        this.loading = true;
        this.applicationService.getAll().subscribe(
            (result: Application[]) => {
                this.updateTableData(result);
            });
    }

    exportData(){
        const data = JSON.stringify(this.dataSource.data, null, 2);
        const blob: Blob = new Blob([data], {type: 'text/json'});
        const fileName = 'export.json';
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    ngOnInit() {
        this.reloadData();
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { User } from '../models/user';


export class ExtendedTableComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>();
  public selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  loading = true;

  constructor() {

    this.loading = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected =
      this.selection == null ? 0 : this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  updateTableData(val: any) {
    this.dataSource.data = val;
    this.dataSource.paginator = this.paginator;
    this.loading = false;
  }
}

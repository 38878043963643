import { Component, OnInit, Inject } from '@angular/core';
import { ChambersService } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chamber } from '../../../../models/chamber';

@Component({
  selector: 'app-edit-chamber-dialog',
  templateUrl: './edit-chamber-dialog.component.html',
  styleUrls: ['./edit-chamber-dialog.component.scss']
})
export class EditChamberDialogComponent implements OnInit {

  chamber = new Chamber();

  constructor(
    private chambersService: ChambersService,
    private alertService: AlertService,
    public dialog: MatDialogRef<EditChamberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.chamber = this.data.model;
  }

  updateChamber() {
    this.chambersService
      .update(this.chamber.id, this.chamber)
      .subscribe((next) => {
        this.alertService.showMessage('Pomyślnie zaktualizowano wirtualną salę', MessageType.Info);
        this.dialog.close(true);
      },
      error => {
        this.alertService.showMessage('Wystąpił nieoczekiwany błąd', MessageType.Error);
      });
  }

}

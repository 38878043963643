import { Component, OnInit, Inject } from '@angular/core';
import { BackupService } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-backup-dialog',
  templateUrl: './create-backup-dialog.component.html',
  styleUrls: ['./create-backup-dialog.component.scss']
})
export class CreateBackupDialogComponent implements OnInit {
  name = 'Kopia zapasowa bazy danych';
  constructor(
    private backupService: BackupService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CreateBackupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
  }

  doBackup() {
    this.backupService.doBackup({name: this.name}).subscribe(result => {
      this.alertService.showMessage('Kopia zapasowa wykonana prawidłowo', MessageType.Info);
      this.dialog.close(true);
    }, error => {
      this.alertService.showMessage('Błąd podczas wykonywania kopii zapasowej', MessageType.Error);
    });
  }
}

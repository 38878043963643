import { Component, OnInit } from '@angular/core';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../api/api';
import { Configuration } from '../../../../configuration';

@Component({
  selector: 'app-ldap-authentication',
  templateUrl: './ldap-authentication.component.html',
  styleUrls: ['./ldap-authentication.component.scss']
})
export class LdapAuthenticationComponent implements OnInit {
  public hidePassword = true;
  public model: any;
  public servers: string[];

  constructor(private httpClient: HttpClient,
    private alertService: AlertService,
    private configuration: Configuration,
    private authenticationService: AuthenticationService,
    private router: Router ) { }

  ngOnInit() {
    this.authenticationService.ldapServers().subscribe(
      (servers: any) => this.servers = servers);
  }

  public authenticate(form) {
    this.authenticationService.authenticateLDAPUser(form.value).subscribe(
      next => {
          // this.alertService.showMessage("Pomyślnie zalogowano", MessageType.Info);
          this.configuration.apiKeys['Authorization'] = next;
          this.router.navigateByUrl('/home');
      },
      error => {
          this.alertService.showMessage(error.error, MessageType.Error);
      });
  }

}

import { RepositoryObjectDto, ApplicationDto } from '../model/models';

export class RepositoryObject implements RepositoryObjectDto {
  id?: number;
  readonly path?: string;
  name?: string;
  directory?: string;
  readonly creationDate?: Date;
  application?: ApplicationDto;

  children?: RepositoryObject[];
  parent?: RepositoryObject;
  readonly type?: string;
  constructor(repositoryObjectDto?: RepositoryObjectDto) {
    if (repositoryObjectDto == null) {
        return;
    }
    Object.assign(this, repositoryObjectDto);
}
}

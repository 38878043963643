import { ExhibitDto } from '../model/exhibitDto';
import { Chamber } from './chamber';

export class Exhibit implements ExhibitDto {
    constructor(dto?: ExhibitDto) {
        if (dto != null) {
            Object.assign(this, dto);
        }
    }
    id?: number;
    name: string;
    description: string;
    chamberId: number;
    chamber: Chamber;
}

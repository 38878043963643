import { Component, OnInit, Inject } from '@angular/core';
import { ExhibitsService } from '../../../..';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditExhibitDialogComponent } from '../edit-exhibit-dialog/edit-exhibit-dialog.component';
import { User } from '../../../../models/user';

@Component({
  selector: 'app-moderators',
  templateUrl: './moderators.component.html',
  styleUrls: ['./moderators.component.scss']
})
export class ModeratorsComponent implements OnInit {

  model: any;
  moderators: User[] = [
    {
      firstName: 'Testowy', id: 0, lastName: 'Tester', username: 'tester1', password: 'qwe', group: null, phoneNumber: null  
    }
  ];

  constructor(
    private exhibitsService: ExhibitsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<EditExhibitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.model = this.data.model;
  }
}

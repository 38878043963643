import { Component, OnInit, ViewChild } from '@angular/core';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTreeNestedDataSource, MatTree } from '@angular/material/tree';
import { RepositoryComponent } from '../pages/repository/repository.component';
import { RepositoryObjectDto } from '../../model/models';
import { RepositoryFile } from '../../models/repositoryFile';
import { Device } from '../../models/device';
import { RepositoryDirectory } from '../../models/repositoryDirectory';
import { RepositoryObject } from '../../models/repositoryObject';
import { FileUploadDialogComponent } from '../dialogs/repository-manager/file-upload-dialog/file-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RepositoryService, ApplicationsService } from '../../api/api';
import { DirectoryCreatorDialogComponent } from '../dialogs/repository-manager/directory-creator-dialog/directory-creator-dialog.component';
import { Application } from '../../models/application';
import { AlertService, MessageType } from '../../services/alert.service';
import { FileRenameDialogComponent } from '../dialogs/repository-manager/file-rename-dialog/file-rename-dialog.component';
import { FilePreviewDialogComponent } from '../dialogs/repository-manager/file-preview-dialog/file-preview-dialog.component';



// tslint:disable: member-ordering





interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  selected: boolean;
  source: RepositoryObject;
  visible: boolean;
}


@Component({
  selector: 'app-repository-manager',
  templateUrl: './repository-manager.component.html',
  styleUrls: ['./repository-manager.component.scss']
})
export class RepositoryManagerComponent implements OnInit {
  private _transformer = (node: RepositoryObject, level: number) => {
    const obj = {
      expandable: node.type === 'RepositoryDirectory' && node.children.length !== 0,
      name: node.name,
      level: level,
      source: node,
      visible: node.type === 'RepositoryDirectory',
      selected: false
    };
    if (this.selectedNode != null &&
      node.name === this.selectedNode.source.name &&
      node.directory === this.selectedNode.source.directory) {
      this.selectedNode.source = node;
    }

    return obj;
  }

  selectedNode: FlatNode = undefined;

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  repositoryObjects: RepositoryObject[] = [];

  constructor(private dialog: MatDialog,
    private repositoryService: RepositoryService,
    private applicationService: ApplicationsService,
    private alertService: AlertService) {
  }
  selectedApplication: Application;
  selectedFiles: RepositoryObject[] = [];
  copiedFiles: RepositoryObject[] = [];
  cutMode = false;

  hasChild = (_: number, node: FlatNode) => node.expandable;
  visible = (_: number, node: FlatNode) => node.visible;

  selectNode(node: FlatNode) {
    this.treeControl.dataNodes.forEach(n => n.selected = false);
    node.selected = true;
    this.selectedNode = node;

    this.selectedApplication = new Application(node.source.application);
  }


  getCurrentPath(): RepositoryObject[] {
    if (this.selectedNode === undefined) {
      return [];
    }
    const arr: RepositoryObject[] = [];
    let node = this.selectedNode.source;
    arr.push(node);
    while ((node = node.parent) !== undefined) {
      arr.push(node);
    }
    return arr.reverse();
  }


  visibleFiles(): RepositoryObject[] {
    if (this.selectedNode === undefined) {
      return [];
    }
    return this.selectedNode.source.children;
  }

  openUploadDialog() {
    const dialogRef = this.dialog.open(FileUploadDialogComponent,
      {
        width: '800px',
        data: { application: this.selectedApplication, directory: this.getCurrentPath().map(s => s.name).slice(1).join('/') }
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      
        this.update();
      
    });
  }

  openRenameDialog() {
    const path = this.getCurrentPath();
    const dialogRef = this.dialog.open(FileRenameDialogComponent,
      {
        width: '400px',
        data: { repositoryObject: this.selectedFiles[0] }
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.update();
      }
    });
  }

  preview() {
    const path = this.getCurrentPath();
    const dialogRef = this.dialog.open(FilePreviewDialogComponent,
      {
        width: '400px',
        data: { repositoryObject: this.selectedFiles[0] }
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.update();
      }
    });
  }

  openDirectoryCreatorDialog() {
    const path = this.getCurrentPath();
    const dialogRef = this.dialog.open(DirectoryCreatorDialogComponent,
      {
        width: '400px',
        data: { application: this.selectedApplication, directory: path.map(s => s.name).slice(1).join('/') }
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.update();
      }
    });
  }

  fillChildren(object: RepositoryObject, objects: RepositoryObject[], dir: string, application: Application) {

    object.children = [];
    objects.forEach(obj => {
      if (dir === obj.directory && obj.directory !== '' && application!=null && application.id == obj.application.id) {
        this.fillChildren(obj, objects, dir === '' ? obj.name : dir + '/' + obj.name, application);
        obj.parent = object;
        object.children.push(obj);
      }
    });
  }

  buildTree(objects: RepositoryObject[], application: Application): RepositoryObject[] {
    const tree: RepositoryObject[] = [];

    objects.filter(o => o.application.id === application.id).forEach(object => {
      if (object.directory == null || object.directory === '') {
        this.fillChildren(object, objects, object.name, application);
        tree.push(object);
      }
    });

    return tree;
  }

  update() {
    this.repositoryService.getAll().subscribe(objects => {
      this.repositoryObjects = objects.map(l => new RepositoryObject(l));
      this.applicationService.getAll().subscribe(result => {
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = result.map(l => new RepositoryDirectory(l.name,
          this.buildTree(this.repositoryObjects,
            new Application(l)),
          undefined,
          new Application(l))
        );
        this.selectedFiles = [];
        if (this.selectedNode == null) {
          this.selectNode(this.treeControl.dataNodes[0]);
        }
      });
    });
  }



  copySelected() {
    this.cutMode = false;
    this.copiedFiles = [...this.selectedFiles];
  }

  cutSelected() {
    this.cutMode = true;
    this.copiedFiles = [...this.selectedFiles];
  }

  paste() {
    this.copiedFiles.forEach(file => {
      let newName = file.name;
      const newDirectory = this.getCurrentPath()
        .map(s => s.name)
        .slice(1)
        .join('/');


      while (this.repositoryObjects.filter(d => d.name === newName && d.directory === newDirectory).length !== 0) {
        newName += ' - Kopia';
      }
      this.repositoryService.clone(
        file.id,
        newName,
        newDirectory,
        this.selectedApplication.id
      ).subscribe(result => {
        if (!this.cutMode) {
          this.alertService
            .showMessage('Pomyślnie skopiowano plik', MessageType.Info);
          this.update();
        } else {
          this.repositoryService.remove(file.id).subscribe(() => {
            this.alertService.showMessage('Pomyślnie przeniesiono plik', MessageType.Info);
            this.update();
          }, () => {
            this.alertService.showMessage('Błąd podczas przenoszenia pliku', MessageType.Error);
          });
        }
      });
    });
    this.copiedFiles = [];
    this.update();
  }

  deleteSelected() {
    this.selectedFiles.forEach(element => {
      this.repositoryService.remove(element.id).subscribe(result => this.update(), error => {
        this.alertService.showMessage('Błąd podczas usuwania pliku', MessageType.Error);
      });
    });
  }

  select(file: RepositoryObject) {
    if (!this.isSelected(file)) {
      this.selectedFiles.push(file);
      this.selectedApplication = new Application(file.application);
    } else {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }
  }

  isSelected(file: RepositoryObject) {
    return (this.selectedFiles.indexOf(file) !== -1);
  }

  ngOnInit(): void {
    this.update();
  }
}

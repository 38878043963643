import { ChamberDto } from '../model/chamberDto';

export class Chamber implements ChamberDto {
    constructor(dto?: ChamberDto) {
        if (dto != null) {
            Object.assign(this, dto);
        }
    }
    id?: number;
    name: string;
    description: string;
}

import { ApplicationDto } from '..';
import { PlayerDto } from '../model/playerDto';
import { Exhibit } from './exhibit';

export class Player implements PlayerDto {
    application?: ApplicationDto;
    computerName?: string;
    macAddress: string;
    key?: string;
    ipAddress?: string;
    systemInfo?: string;
    type?: string;
    id?: number;
    name: string;
    exhibitId?: number;
    exhibit: Exhibit;
    constructor(playerDto?: PlayerDto) {
        if (playerDto == null) {
            return;
        }
        Object.assign(this, playerDto);
    }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {

  constructor( private router: Router,
    private configuration: Configuration) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.configuration.apiKeys['Authorization'] !== undefined && this.configuration.apiKeys['Authorization'] !== 'KEY') {
      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }
}

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AlertService, MessageType } from '../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Application } from '../../../models/application';
import { MatTableModule } from '@angular/material/table';
import { HttpEventType } from '@angular/common/http';
import { Form } from '@angular/forms';
import { ApplicationsService } from '../../../api/applications.service';

@Component({
  selector: 'app-view-application-dialog',
  templateUrl: './view-application-dialog.component.html',
  styleUrls: ['./view-application-dialog.component.scss']
})
export class ViewApplicationDialogComponent implements OnInit {

  constructor(
    private applicationService: ApplicationsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<ViewApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  application = new Application();

  ngOnInit() {
    this.application = this.data.model;
  }
}

/**
 * EMS API
 * Exposition Management System API Documentation
 *
 * OpenAPI spec version: v2
 * Contact: mateusz.surma@mae.pl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApplicationConfigurationDto } from '../model/applicationConfigurationDto';
import { ApplicationDto } from '../model/applicationDto';
import { ApplicationVersionDto } from '../model/applicationVersionDto';
import { IncrementalStatisticsDto } from '../model/incrementalStatisticsDto';
import { TimespanStatisticsDto } from '../model/timespanStatisticsDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApplicationsService {

    protected basePath = 'http://localhost:9220/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds application to the database
     * 
     * @param body JSON Dto model
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public add(body?: ApplicationDto, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDto>;
    public add(body?: ApplicationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDto>>;
    public add(body?: ApplicationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDto>>;
    public add(body?: ApplicationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApplicationDto>(`${this.basePath}/api/Applications`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds application configuration to the database
     * 
     * @param body Application configuration dto
     * @param applicationId Application id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addConfiguration(body?: ApplicationConfigurationDto, applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationConfigurationDto>;
    public addConfiguration(body?: ApplicationConfigurationDto, applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationConfigurationDto>>;
    public addConfiguration(body?: ApplicationConfigurationDto, applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationConfigurationDto>>;
    public addConfiguration(body?: ApplicationConfigurationDto, applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApplicationConfigurationDto>(`${this.basePath}/api/Applications/Configurations`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds incremental statistics
     * 
     * @param body Incremental statistics with name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addIncrementStatistics(body?: IncrementalStatisticsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addIncrementStatistics(body?: IncrementalStatisticsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addIncrementStatistics(body?: IncrementalStatisticsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addIncrementStatistics(body?: IncrementalStatisticsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Applications/Statistics/Incremental`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds timespan statistics
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTimespanStatistics(body?: TimespanStatisticsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addTimespanStatistics(body?: TimespanStatisticsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addTimespanStatistics(body?: TimespanStatisticsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addTimespanStatistics(body?: TimespanStatisticsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Applications/Statistics/Timespan`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a single application from the database
     * 
     * @param id ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(id: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDto>;
    public get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDto>>;
    public get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDto>>;
    public get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApplicationDto>(`${this.basePath}/api/Applications/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all entities from the database
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll(observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDto>>;
    public getAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDto>>>;
    public getAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDto>>>;
    public getAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApplicationDto>>(`${this.basePath}/api/Applications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets application configuration with given ID from the database
     * 
     * @param configurationId Configuration ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfiguration(configurationId: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationConfigurationDto>;
    public getConfiguration(configurationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationConfigurationDto>>;
    public getConfiguration(configurationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationConfigurationDto>>;
    public getConfiguration(configurationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (configurationId === null || configurationId === undefined) {
            throw new Error('Required parameter configurationId was null or undefined when calling getConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApplicationConfigurationDto>(`${this.basePath}/api/Applications/Configurations/${encodeURIComponent(String(configurationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all application configurations from the database
     * 
     * @param applicationId Application ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfigurations(applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationConfigurationDto>>;
    public getConfigurations(applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationConfigurationDto>>>;
    public getConfigurations(applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationConfigurationDto>>>;
    public getConfigurations(applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApplicationConfigurationDto>>(`${this.basePath}/api/Applications/Configurations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets incremental statistics
     * 
     * @param applicationId Application ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncrementalStatistics(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TimespanStatisticsDto>>;
    public getIncrementalStatistics(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TimespanStatisticsDto>>>;
    public getIncrementalStatistics(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TimespanStatisticsDto>>>;
    public getIncrementalStatistics(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getIncrementalStatistics.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TimespanStatisticsDto>>(`${this.basePath}/api/Applications/Statistics/Incremental/${encodeURIComponent(String(applicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets timespan statistics
     * 
     * @param applicationId Application ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTimespanStatistics(applicationId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TimespanStatisticsDto>>;
    public getTimespanStatistics(applicationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TimespanStatisticsDto>>>;
    public getTimespanStatistics(applicationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TimespanStatisticsDto>>>;
    public getTimespanStatistics(applicationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getTimespanStatistics.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TimespanStatisticsDto>>(`${this.basePath}/api/Applications/Statistics/Timespan/${encodeURIComponent(String(applicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets application version with given ID from the database
     * 
     * @param versionId Version ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersion(versionId: number, observe?: 'body', reportProgress?: boolean): Observable<ApplicationVersionDto>;
    public getVersion(versionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationVersionDto>>;
    public getVersion(versionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationVersionDto>>;
    public getVersion(versionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getVersion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApplicationVersionDto>(`${this.basePath}/api/Applications/Versions/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Version file download
     * 
     * @param versionId Version ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersionFile(versionId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getVersionFile(versionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getVersionFile(versionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getVersionFile(versionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getVersionFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/Applications/Versions/${encodeURIComponent(String(versionId))}/File`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Gets all application versions from the database
     * 
     * @param applicationId Application ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersions(applicationId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationVersionDto>>;
    public getVersions(applicationId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationVersionDto>>>;
    public getVersions(applicationId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationVersionDto>>>;
    public getVersions(applicationId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('applicationId', <any>applicationId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApplicationVersionDto>>(`${this.basePath}/api/Applications/Versions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes application from the database
     * 
     * @param id ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public remove(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public remove(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public remove(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public remove(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling remove.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Applications/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes application configuration from the database
     * 
     * @param configurationId Configuration ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeConfiguration(configurationId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeConfiguration(configurationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeConfiguration(configurationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeConfiguration(configurationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (configurationId === null || configurationId === undefined) {
            throw new Error('Required parameter configurationId was null or undefined when calling removeConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Applications/Configurations/${encodeURIComponent(String(configurationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove application version from the database
     * 
     * @param versionId Version ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeVersion(versionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeVersion(versionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeVersion(versionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeVersion(versionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling removeVersion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Applications/Versions/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates application in the database
     * 
     * @param id ID
     * @param body JSON Dto model
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(id: number, body?: ApplicationDto, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDto>;
    public update(id: number, body?: ApplicationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDto>>;
    public update(id: number, body?: ApplicationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDto>>;
    public update(id: number, body?: ApplicationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling update.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApplicationDto>(`${this.basePath}/api/Applications/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates application configuration in the database
     * 
     * @param id ID
     * @param body Application configuration dto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConfiguration(id: number, body?: ApplicationConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<ApplicationConfigurationDto>;
    public updateConfiguration(id: number, body?: ApplicationConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationConfigurationDto>>;
    public updateConfiguration(id: number, body?: ApplicationConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationConfigurationDto>>;
    public updateConfiguration(id: number, body?: ApplicationConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateConfiguration.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApplicationConfigurationDto>(`${this.basePath}/api/Applications/Configurations/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds application versions to the database
     * 
     * @param applicationId Application ID
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadVersion(applicationId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationVersionDto>>;
    public uploadVersion(applicationId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationVersionDto>>>;
    public uploadVersion(applicationId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationVersionDto>>>;
    public uploadVersion(applicationId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling uploadVersion.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.post<Array<ApplicationVersionDto>>(`${this.basePath}/api/Applications/${encodeURIComponent(String(applicationId))}/Versions`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

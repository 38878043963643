import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';

import { MultilanguageValueDto } from '../../../../model/models';
import { MultilanguageVarible } from '../../../../models/multilanguageVarible';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Editor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';


@Component({
  selector: 'app-advanced-varible-editor-dialog',
  templateUrl: './advanced-varible-editor-dialog.component.html',
  styleUrls: ['./advanced-varible-editor-dialog.component.scss']
})
export class AdvancedVaribleEditorDialogComponent implements OnInit {
  varible: MultilanguageVarible;
  valuePl: string;
  valueEn: string;

  public config = {
    toolbar: ['bold', 'italic', 'strikethrough', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    language: 'pl',
    removePlugins: ['ImageUpload'],
    autoParagraph: false,
    enterMode: 2,
    coreStyles_bold: { element: 'b', overrides: 'strong' }
  };
  public editor = Editor;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialogRef<AdvancedVaribleEditorDialogComponent>) { }

  ngOnInit() {
    this.varible = this.data.model;
    this.valuePl = this.varible.multilanguageValuesDto.find(f => f.language === 'pl').value;
    this.valueEn = this.varible.multilanguageValuesDto.find(f => f.language === 'en').value;
  }

  save() {
    if (this.valuePl.startsWith('<p>') && this.valuePl.endsWith('</p>')) {
      this.valuePl = this.valuePl.substring(3, this.valuePl.length - 4);
    }
    if (this.valueEn.startsWith('<p>') && this.valueEn.endsWith('</p>')) {
      this.valueEn = this.valueEn.substring(3, this.valueEn.length - 4);
    }

    this.data.model.multilanguageValuesDto.find(f => f.language === 'pl').value = this.valuePl;
    this.data.model.multilanguageValuesDto.find(f => f.language === 'en').value = this.valueEn;

  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { RepositoryService } from '../../../..';
import { AlertService } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileRenameDialogComponent } from '../file-rename-dialog/file-rename-dialog.component';
import { RepositoryObject } from '../../../../models/repositoryObject';
import { DomSanitizer } from '@angular/platform-browser';
import { RepositoryFile } from '../../../../models/repositoryFile';
import * as moment from 'moment';

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss']
})
export class FilePreviewDialogComponent implements OnInit {
  file: RepositoryObject;
  constructor(public repositoryService: RepositoryService,
    private alertService: AlertService,
    public dialog: MatDialogRef<FileRenameDialogComponent>,
    private sanitization: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data) { }

  mediaLoading = false;
  mediaSouce: any;

  date: string;
  fullType: string;
  fileType: 'Obraz' | 'Film' | 'Archiwum' | 'Nieznany';

  parseFileSize(size) {
    const kb = size / 1000;
    const mb = kb / 1000;
    const gb = mb / 1000;
    return gb >= 1 ? gb.toFixed(3) + ' GB' : (mb >= 1 ? mb.toFixed(3) + ' MB' : (kb >= 1 ? kb.toFixed(3) + ' KB' : size + ' B'));
  }

  getImageFromService() {
    this.mediaLoading = true;
    this.repositoryService.download(this.file.id).subscribe(data => {
      const objectURL = URL.createObjectURL(data);
      this.mediaSouce = this.sanitization.bypassSecurityTrustUrl(objectURL);
      this.mediaLoading = false;
    }, error => {
      this.mediaLoading = false;
    });
  }

  getVideoFromService() {
    this.mediaLoading = true;
    this.repositoryService.download(this.file.id).subscribe(data => {
      const objectURL = URL.createObjectURL(data);
      this.mediaSouce = this.sanitization.bypassSecurityTrustUrl(objectURL);
      this.mediaLoading = false;
    }, error => {
      this.mediaLoading = false;
    });
  }

  getFileType(): 'Obraz' | 'Film' | 'Archiwum' | 'Nieznany' {
    const f = this.file as RepositoryFile;
    switch (f.extension.toLowerCase()) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'tiff':
      case 'webp':
        return 'Obraz';
      case 'ogg':
      case 'mp4':
      case 'webm':
        return 'Film';
      case 'zip':
      case 'rar':
        return 'Archiwum';
      default:
        return 'Nieznany';
    }
  }

  ngOnInit() {
    this.file = this.data.repositoryObject;
    this.fileType = this.getFileType();
    moment.locale('pl');
    this.date = moment(this.file.creationDate).format('LLLL');

    if (this.fileType === 'Obraz') {
      this.getImageFromService();
    } else if (this.fileType === 'Film') {
      this.getVideoFromService();
    }

    this.fullType = this.fileType + ' (' + (this.file as RepositoryFile).extension + ')';
  }

}

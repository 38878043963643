import { Component, OnInit } from '@angular/core';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../api/api';
import { Configuration } from '../../../../configuration';

@Component({
    selector: 'app-internal-authentication',
    templateUrl: './internal-authentication.component.html',
    styleUrls: ['./internal-authentication.component.scss']
})
export class InternalAuthenticationComponent implements OnInit {

    public hidePassword = true;

    constructor(
        private alertService: AlertService,
        private configuration: Configuration,
        private authenticationService: AuthenticationService,
        private router: Router) { }

    ngOnInit() {

    }

    public authenticate(form) {
        this.authenticationService.authenticateInternalUser(form.value).subscribe(
          next => {
              // this.alertService.showMessage("Pomyślnie zalogowano", MessageType.Info);
              this.configuration.apiKeys['Authorization'] = 'Bearer ' + next;
              this.router.navigateByUrl('/');
          },
          error => {
              this.alertService.showMessage(error.error, MessageType.Error);
          });
      }

}

import { Component, OnInit, Input } from '@angular/core';
import { Projector } from '../../../../models/projector';

@Component({
  selector: 'app-projector-form',
  templateUrl: './projector-form.component.html',
  styleUrls: ['./projector-form.component.scss']
})
export class ProjectorFormComponent implements OnInit {

  @Input() public projector: Projector;
  @Input() readonly: boolean;
  constructor() { }

  ngOnInit() {
  }

}

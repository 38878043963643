import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ScheduleTask } from '../../../../models/scheduleTask';
import { ScheduleTasksService, ScheduleTaskDto, Chamber, Exhibit } from '../../../..';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Device } from '../../../../models/device';
import { ExhibitsViewComponent } from '../../../pages/devices/exhibits-view/exhibits-view.component';
import { DeviceViewComponent } from '../../../pages/devices/device-view/device-view.component';
import { ChambersViewComponent } from '../../../pages/devices/chambers-view/chambers-view.component';

@Component({
  selector: 'app-create-schedule-task-dialog',
  templateUrl: './create-schedule-task-dialog.component.html',
  styleUrls: ['./create-schedule-task-dialog.component.scss']
})
export class CreateScheduleTaskDialogComponent implements OnInit {
  scheduleTask: ScheduleTask = {
    actionTime: '00:00'
  };

  selectedChambers: Chamber[];
  selectedExhibits: Exhibit[];
  selectedDevices: Device[];

  @ViewChild('exhibits', {static: false}) exhibitsTable: ExhibitsViewComponent;
  @ViewChild('devices', {static: false}) devicesTable: DeviceViewComponent;
  @ViewChild('chambers', {static: false}) chambersTable: ChambersViewComponent;

  constructor(
    private scheduleTasksService: ScheduleTasksService,
    private alertService: AlertService,
    public dialog: MatDialogRef<CreateScheduleTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  setSelectedChambers($event) {
    this.selectedChambers = $event.selection.selected;
    this.selectedChambers.forEach(chamber => {
      this.exhibitsTable.dataSource.data.forEach((row: Exhibit) => {
        if (row.chamberId === chamber.id) {
          this.exhibitsTable.selection.select(row);
        }
      });
    });
  }

  setSelectedExhibits($event) {
    this.selectedExhibits = $event.selection.selected;
    this.selectedExhibits.forEach(exhibit => {
      this.devicesTable.dataSource.data.forEach((row: Device) => {
        if (row.exhibitId === exhibit.id) {
          this.devicesTable.selection.select(row);
        }
      });
    });
  }

  setSelectedDevices($event) {
    this.selectedDevices = $event.selection.selected;
    this.scheduleTask.devices = this.selectedDevices;
  }

  createScheduleTask() {
    this.scheduleTasksService
    .add(this.scheduleTask)
    .pipe(map(scheduleTaskDto => new ScheduleTask(scheduleTaskDto)))
    .subscribe((next) => {
      this.alertService.showMessage('Pomyślnie utworzono wydarzenie harmonogramu', MessageType.Info);
      this.scheduleTask = next;
      console.log(this.scheduleTask);
      this.dialog.close(true);
    },
    (error) => {
      this.alertService.showMessage('Błąd podczas tworzenia wydarzenia harmonogramu: ' + error.error, MessageType.Info);
    });
  }

}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { MultilanguageVarible } from '../../models/multilanguageVarible';
import * as Editor from '@ckeditor/ckeditor5-build-classic';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, concat, of, defer, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdvancedVaribleEditorDialogComponent } from '../dialogs/multilanguage-varibles/advanced-varible-editor-dialog/advanced-varible-editor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFileReaderComponent } from '../dynamic-file-reader/dynamic-file-reader.component';
import { MultilanguageValueDto } from '../../model/models';

@Component({
  selector: 'app-multilanguage-varibles-editor',
  templateUrl: './multilanguage-varibles-editor.component.html',
  styleUrls: ['./multilanguage-varibles-editor.component.scss']
})
export class MultilanguageVariblesEditorComponent implements OnInit {

  _data: MultilanguageVarible[] = [];

  @Output() dataChange = new EventEmitter();
  @Input() get data(): MultilanguageVarible[] {
    return this._data;
  }
  set data(val) {
    this._data = val;
    this.dataChange.emit(val);
  }

  disableEditMode = true;

  constructor(private dialog: MatDialog) { }

  public editorInstance;

  @ViewChild('editor', { static: false }) editor: ElementRef;

  addNewVarible() {
    this._data.push(
      {
        name: 'Nowa wartość',
        multilanguageValuesDto:
          [
            { language: 'pl', value: '' },
            { language: 'en', value: '' },
          ]
      });
  }

  removeVarible(varible) {
    this.data.splice(this.data.indexOf(varible), 1);
  }

  advancedEdit(varible) {
    this.dialog.open(AdvancedVaribleEditorDialogComponent,
      {
        width: '700px',
        data: { model: varible },
      });
  }

  import() {
    const dialogRef = this.dialog.open(DynamicFileReaderComponent,
      {
        width: '700px',
      });

    dialogRef.afterClosed().subscribe(result => {

      this.getVaribles(JSON.parse(result));

    });
  }

  addValueFromImport(name, val) {
    this._data.push(
      {
        name: name,
        multilanguageValuesDto:
          [
            { language: 'pl', value: val },
            { language: 'en', value: '' },
          ]
      });
  }

  getVaribles(obj, currentPath = '') {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if ((typeof obj[key]) === 'object' && !Array.isArray(obj[key])) {
        const cPath = currentPath + key + '.';
        this.getVaribles(obj[key], cPath);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((element, index) => {
          const cPath = currentPath + key + '[' + index + ']';
          if ((typeof element) === 'object' && !Array.isArray(element)) {
            this.getVaribles(element, cPath + '.');
          } else {
            if (Array.isArray(element)) {
              alert('Błąd (Array In Array)');
              element.forEach((innerElement, innerIndex) => {
                const cPathx = cPath + '[' + innerIndex + '].';
                this.getVaribles(innerElement, cPathx);
              });
            } else {
              this.addValueFromImport(cPath, element);
            }
          }
        });
      } else {
        this.addValueFromImport(currentPath + key, obj[key]);
      }
    });
  }

  getPolishModel(multilanguageValuesDto: MultilanguageValueDto[]): number {
    return multilanguageValuesDto.indexOf(multilanguageValuesDto.find(m => m.language === 'pl'));
  }

  getEnglishModel(multilanguageValuesDto: MultilanguageValueDto[]): number {
    return multilanguageValuesDto.indexOf(multilanguageValuesDto.find(m => m.language === 'en'));
  }

  isNullOrEmpty(value: string){
    return (value === undefined || value === '');
  }

  ngOnInit(): void {
    Editor
      .create(this.editor, {
        removePlugins: ['Heading', 'Link'],
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote']
      });
  }

}

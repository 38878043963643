import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExhibitsService } from '../../../../api/exhibits.service';
import { Exhibit } from '../../../../models/exhibit';
import { map } from 'rxjs/operators';
import { ExhibitDto } from '../../../../model/exhibitDto';

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss']
})
export class DeviceFormComponent implements OnInit {
  @Input() public device: any;
  @Input() readonly: boolean;
  @Input() editMode = false;
  @ViewChild('form', {static: true}) public form: NgForm;

  deviceTypes = ['Projektor', 'Komputer', 'Telewizor'];
  exhibits: Exhibit[];

  constructor(private exhibitsService: ExhibitsService) { }

  ngOnInit() {
    this.exhibitsService.getAll()
    .pipe(map((dtos: ExhibitDto[]) => dtos.map((dto) => new Exhibit(dto))))
    .subscribe(next => {
      this.exhibits = next;
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Label, SingleDataSet, BaseChartDirective, Colors } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { ServerManagementService } from '../../../../api/api';

@Component({
  selector: 'app-disk-usage',
  templateUrl: './disk-usage.component.html',
  styleUrls: ['./disk-usage.component.scss']
})
export class DiskUsageComponent implements OnInit {
  constructor(private serverManagementService: ServerManagementService) { }

  isLoading = true;

  public doughnutChartLabels: Label[] = ['Zajęte miejsce', 'Wolne miejsce'];
  public doughnutChartData = [0, 0];
  public doughnutChartOptions: ChartOptions = {
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontSize: 17,

        fontFamily: 'Roboto,"Helvetica Neue",sans-serif'
      }
    }
  };
  public doughnutChartColors: any =  [
    { backgroundColor: [ '#e31e13', '#1f1f1f'] },
    { borderColor: [ '#e31e13', '#1f1f1f' ] }
  ];
  public doughnutChartType: Chart.ChartType = 'doughnut';

  ngOnInit() {
    this.serverManagementService.getDiskUsage('C').subscribe(result => {
        this.doughnutChartData = [result.usedBytes / 1000.0 / 1000.0 / 1000.0, result.freeBytes / 1000.0 / 1000.0 / 1000.0];
      });
    this.isLoading = false;
  }

}

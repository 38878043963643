import { ApplicationDto } from '../model/models';
import { ApplicationVersion } from './applicationVersion';
import { ApplicationConfiguration } from './applicationConfiguration';
import { MultilanguageVarible } from './multilanguageVarible';


export class Application implements ApplicationDto {
    constructor(applicationDto?: ApplicationDto) {
        if (applicationDto != null) {
            Object.assign(this, applicationDto);
        }
    }

    id?: number;
    name?: string;
    description?: string;
    version?: ApplicationVersion;
    config?: ApplicationConfiguration;
    versions?: Array<ApplicationVersion>;
    configurations?: Array<ApplicationConfiguration>;
    multilanguageVaribles?: Array<MultilanguageVarible>;
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ExtendedTableComponent } from '../../../../helpers/extended-management';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { ExhibitsService } from '../../../..';
import { CreateExhibitDialogComponent } from '../../../dialogs/exhibit/create-exhibit-dialog/create-exhibit-dialog.component';
import { EditExhibitDialogComponent } from '../../../dialogs/exhibit/edit-exhibit-dialog/edit-exhibit-dialog.component';
import { ViewExhibitDialogComponent } from '../../../dialogs/exhibit/view-exhibit-dialog/view-exhibit-dialog.component';
import { Exhibit } from '../../../../models/exhibit';
import { Chamber } from '../../../../models/chamber';
import { ModeratorsComponent } from '../../../dialogs/exhibit/moderators/moderators.component';
import { DevicesService } from '../../../../api/api';


@Component({
  selector: 'app-exhibits-view',
  templateUrl: './exhibits-view.component.html',
  styleUrls: ['./exhibits-view.component.scss']
})
export class ExhibitsViewComponent extends ExtendedTableComponent implements OnInit {

  displayedColumns = ['select', 'name', 'description', 'chamber', 'details'];

  @Input() readonly = false;
  @Input() chamber: Chamber;
  @Output() exhibitSelection = new EventEmitter();
  @Output() selectionChange = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private exhibitsService: ExhibitsService,
    private devicesService: DevicesService,
    private dialog: MatDialog
  ) {
    super();
  }

  createDialog() {
    const dialogRef = this.dialog.open(CreateExhibitDialogComponent,
      {
        width: '600px'
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  editDialog(model) {
    const dialogRef = this.dialog.open(EditExhibitDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } },
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

moderatorsDialog(model) {
  const dialogRef = this.dialog.open(ModeratorsComponent,
    {
      width: '600px',
      data: { model: { ...model } },
    });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result) {
      this.reloadData();
    }
  });
}

action(model, action) {
  this.devicesService.getAll(null, model.id).subscribe(devices => devices.forEach(device => {
    this.devicesService.callAction(device.id, action).subscribe(result => {
      this.alertService.showMessage('Akcja wywołana poprawnie', MessageType.Info);
    }, error => {
      this.alertService.showMessage('Błąd podczas wywoływania akcji', MessageType.Error);
      console.error(error);
    });
  }));
}


  viewDialog(model) {
    const dialogRef = this.dialog.open(ViewExhibitDialogComponent,
      {
        width: '600px',
        data: { model: { ...model } }
      });
  }

  exportData(){
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], {type: 'text/json'});
    const fileName = 'export.json';
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

  deleteSelected() {
    this.selection.selected.forEach(element => {
      this.exhibitsService.remove(element.id).subscribe(next => {
      }, error => {
        this.alertService.showMessage('Wystąpił błąd podczas usuwania aplikacji: ' + error.error, MessageType.Error);
      }, () => {
        this.alertService.showMessage('Pomyślnie usunięto stanowisko', MessageType.Info);
        this.selection.clear();
        this.reloadData();
      });
    });
  }

  public reloadData(chamberFilter: Chamber = null) {
    this.loading = true;
      this.exhibitsService
        .getAll(chamberFilter != null ? chamberFilter.id : null)
        .pipe(map(((dtos) => dtos.map(((dto) => new Exhibit(dto))))))
        .subscribe((result: Exhibit[]) => {
          this.updateTableData(result);
        }, error => {
          this.alertService.showMessage('Błąd podczas wczytywania danych', MessageType.Error);
        });
  }

  showDevices(model) {
    this.exhibitSelection.emit({exhibit: model});
  }

  ngOnInit() {
    this.reloadData();
    this.selection.changed.subscribe(next => {
      this.selectionChange.emit({selection: this.selection});
    });
  }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChambersService } from '../../../../api/api';
import { Chamber } from '../../../../models/chamber';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-exhibit-form',
  templateUrl: './exhibit-form.component.html',
  styleUrls: ['./exhibit-form.component.scss']
})
export class ExhibitFormComponent implements OnInit {

  @Input() exhibit: any;

  @Input() readonly: boolean;

  @ViewChild('form', {static: true}) public form: NgForm;

  chambers: Chamber[];

  constructor(private chambersService: ChambersService) { }

  ngOnInit(): void {
    this.chambersService
    .getAll(null)
    .pipe(map(((dtos) => dtos.map(((dto) => new Chamber(dto))))))
    .subscribe((result: Chamber[]) => {
      this.chambers = result;
    }, error => {
      throw new Error('Nie można załadowac listy sal');
    });
  }

}
